/**
 * Password rule that can be imported in Vuelidate. Password requirement:
 * - 1 lowercase
 * - 1 uppercase
 * - 1 digit
 * - 8 characters minimum
 */

const validator = (value: string): boolean => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value);
};

export default validator;
