<template>
    <div>
        <div>
            <span>{{ $t("shared.components.associations.selectBranchHelp.text") }}</span>
            <a href="#" @click.prevent="handleClick">{{
                $t("shared.components.associations.selectBranchHelp.anchor")
            }}</a>
        </div>

        <SupervisorListModal :is-open="isSupervisorListModalOpen" :supervisors="supervisors" @close="handleClose" />
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";

import { AssociationSupervisor } from "@store/resources/Association";

import SupervisorListModal from "./SupervisorListModal.vue";

export default Vue.extend({
    components: { SupervisorListModal },
    props: {
        supervisors: {
            required: true,
            type: Array as PropType<Partial<AssociationSupervisor>[]>,
        },
    },
    data() {
        return {
            isSupervisorListModalOpen: false,
        };
    },
    methods: {
        handleClick(): void {
            this.toggleSupervisorListModal(true);
        },
        handleClose(): void {
            this.toggleSupervisorListModal(false);
        },
        toggleSupervisorListModal(isOpen: boolean): void {
            this.isSupervisorListModalOpen = isOpen;
        },
    },
});
</script>
