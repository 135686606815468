<template>
    <div
        class="heading"
        :class="[
            alignTop ? 'align-items-start' : 'align-items-center',
            {
                'mt-5': marginTop,
                'justify-content-center': centerAlign,
            },
        ]"
    >
        <div class="d-flex flex-column" :class="{ 'align-items-end': rightAlign, 'flex-grow-1': fullWidth }">
            <div v-if="backlink" class="d-flex justify-content-between">
                <b-button v-bind="backlinkButton" class="mb-4 mr-auto">
                    <icon class="mr-2" icon="chevron-left" />
                    {{ backlink.content || $t("shared.layout.back") }}
                </b-button>

                <slot name="actions" />
            </div>

            <h6 v-else-if="subtitle" class="uppercase-subtitle">{{ subtitle }}</h6>

            <div class="align-content-between d-flex">
                <div>
                    <h1 v-if="level == 1" class="m-0">
                        {{ title }}
                        <small v-if="optional" class="font-weight-normal text-muted">
                            • {{ $t("shared.layout.optional") }}
                        </small>
                    </h1>
                    <h2 v-else-if="level == 2" class="m-0">
                        {{ title }}
                        <small v-if="optional" class="font-weight-normal text-muted">
                            • {{ $t("shared.layout.optional") }}
                        </small>
                    </h2>

                    <slot name="badge" />
                </div>

                <slot name="left" />
            </div>
            <span v-if="description" v-dompurify-html="strip(description)" class="text-muted"></span>
        </div>

        <div v-if="button">
            <b-button
                v-if="button.action"
                class="ml-2"
                :class="button.className"
                :data-cy="button.cypressTag"
                :disabled="button.disabled || false"
                :variant="button.variant || 'primary'"
                @click="button.action"
            >
                <icon
                    v-if="button.icon"
                    :class="{
                        'mr-2': button.content,
                    }"
                    :icon="button.icon"
                />
                {{ button.content }}
            </b-button>
            <b-button
                v-if="button.href"
                class="ml-2"
                :class="button.className"
                :disabled="button.disabled || false"
                :href="button.href"
                :target="button.blank ? '_blank' : '_self'"
                :variant="button.variant || 'primary'"
            >
                <icon
                    v-if="button.icon"
                    :class="{
                        'mr-2': button.content,
                    }"
                    :icon="button.icon"
                />
                {{ button.content }}
            </b-button>
            <b-button
                v-else-if="button.to"
                class="ml-2"
                :class="button.className"
                :to="button.to"
                :variant="button.variant || 'primary'"
            >
                <icon v-if="button.icon" class="mr-2" :icon="button.icon" />
                {{ button.content }}
            </b-button>
        </div>
        <template v-else-if="hasSlot">
            <div class="heading-slot ml-3">
                <slot />
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { RawLocation } from "vue-router";

import { strip } from "@utils/markdown";

export type Backlink =
    | {
          content?: string;
          href: string;
      }
    | {
          content?: string;
          to: RawLocation;
      };

export type HeadingButton = {
    action?: () => void;
    className?: string;
    content?: string;
    cypressTag?: string;
    href?: string;
    icon?: string;
    to?: RawLocation;
    variant?: string;
    disabled?: boolean;
};

export default Vue.extend({
    props: {
        alignTop: Boolean,
        backlink: Object as PropType<Backlink>,
        button: Object as PropType<HeadingButton>,
        centerAlign: Boolean,
        description: String,
        level: {
            default: "1",
            type: String as PropType<"1" | "2">,
        },
        marginTop: Boolean,
        rightAlign: Boolean,
        fullWidth: Boolean,
        optional: {
            default: false,
            type: Boolean,
        },
        subtitle: String,
        title: String,
    },
    computed: {
        backlinkButton(): any {
            if (!this.backlink) {
                return null;
            }

            if (this.backlink.href) {
                return {
                    href: this.backlink.href,
                    tag: "a",
                };
            }

            if (this.backlink.to) {
                return {
                    tag: "router-link",
                    to: this.backlink.to,
                };
            }

            throw new Error(`Unknown backlink type`);
        },
        hasSlot(): boolean {
            return !!this.$slots.default;
        },
    },
    methods: {
        strip,
    },
});
</script>
