<template>
    <div class="layout-footer">
        <footer v-if="type === 'employee'" class="layout-footer-employee">
            <div class="container">
                <div class="row">
                    <div
                        v-dompurify-html="
                            $t('shared.components.layout.footer.propulsedBy', {
                                company: currentCompany.name,
                                website_url: 'https://vendredi.cc',
                            })
                        "
                        class="col-12 col-md-4 mb-1 mb-md-0 text-center"
                    />

                    <div class="col-12 col-md-3 mb-1 mb-md-0 text-center">
                        <a :href="privacyPolicyUrl" target="_blank">
                            {{ $t("shared.components.layout.footer.privacyPolicy") }}
                        </a>
                    </div>

                    <div class="col-12 col-md-3 mb-1 mb-md-0 text-center">
                        <a :href="termsOfUseUrl" target="_blank">
                            {{ $t("shared.components.layout.footer.termsOfUse") }}
                        </a>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <a v-if="!isUserLoggedIn" :href="loginUrl">
                            {{ $t("shared.components.layout.footer.login") }}
                        </a>

                        <a v-else :href="logoutUrl">
                            {{ $t("shared.components.layout.footer.logout") }}
                        </a>
                    </div>
                </div>
            </div>
        </footer>

        <footer v-else class="layout-footer-public">
            <div class="row">
                <div class="align-items-center col-12 col-md-4 d-flex justify-content-center">
                    <p class="mb-0">
                        {{ $t("shared.components.layout.footer.allRightsReserved", { year: currentYear }) }}
                    </p>
                </div>

                <div class="align-items-center col-12 col-md-4 d-flex justify-content-center">
                    <a class="link" :href="privacyPolicyUrl" target="_blank">
                        {{ $t("shared.components.layout.footer.privacyPolicy") }}
                    </a>
                </div>

                <div class="align-items-center col-12 col-md-4 d-flex justify-content-center">
                    <a class="link" :href="termsOfUseUrl" target="_blank">
                        {{ $t("shared.components.layout.footer.termsOfUse") }}
                    </a>
                </div>
            </div>
        </footer>

        <form id="logout-form" :action="logoutUrl" method="POST" style="display: none">
            <!-- Legacy Laravel -->
            <input name="_token" type="hidden" :value="csrfToken" />
        </form>
    </div>
</template>

<style lang="scss" scoped>
@import "variables";

.layout-footer {
    &-employee {
        margin-top: 30px;
        background-color: $color-white;
        color: $text-muted;
        position: relative;
        padding: 25px 0;
        min-height: 70px;

        > a {
            text-decoration: underline;
        }
    }

    &-public {
        color: $body-color;
        font-size: 16px;
        padding: 30px 50px;
        margin-top: 60px !important;

        .link {
            color: $body-color !important;
            text-decoration: underline !important;
        }
    }
}
</style>

<script lang="ts">
import moment from "moment";
import Vue, { PropType } from "vue";

import { Company } from "@store/resources";
import ExternalRouter from "@utils/externalRouter";
import { toHTML } from "@utils/markdown";

type FooterType = "employee" | "public";

export default Vue.extend({
    props: {
        type: {
            type: String as PropType<FooterType>,
        },
    },
    data() {
        return {
            csrfToken: window.Laravel as PropType<string>,
        };
    },
    computed: {
        currentCompany(): Company | null {
            return this.$store?.getters.currentCompany;
        },
        currentYear(): string {
            return moment().format("YYYY");
        },
        isUserLoggedIn(): boolean {
            return !!this.$store.getters.currentUser;
        },
        loginUrl(): string {
            if (this.currentCompany) {
                return ExternalRouter.url("public.employee_login");
            }

            return ExternalRouter.url("login");
        },
        logoutUrl(): string {
            return ExternalRouter.url("logout");
        },
        privacyPolicyUrl(): string {
            if (this.currentCompany) {
                return this.currentCompany.privacy_policy_filepath ?? ExternalRouter.url("company.privacy_policy");
            }

            return ExternalRouter.url("privacy_policy");
        },
        termsOfUseUrl(): string {
            return this.currentCompany?.terms_of_use_filepath || ExternalRouter.url("terms_of_use");
        },
    },
    methods: {
        toHTML,
    },
});
</script>
