<template>
    <div class="panel-arc">
        <div class="arc">
            <div class="content">
                <slot />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "variables";

.panel-arc {
    background: $color-white;
    padding-top: 220px;
    overflow: hidden;

    .arc {
        position: relative;
        background: $color-secondary-extra-light;
        padding: 80px 100px;

        @media screen and (max-width: 991px) {
            padding: 80px 24px;
        }

        &::before {
            content: "";
            position: absolute;
            border-radius: 50%;
            background: $color-secondary-extra-light;
            height: 1100px;
            width: 1100px;
            top: -200px;
            left: 50%;
            right: 0;
            transform: translateX(-50%);
        }

        .content {
            position: relative;
            margin-top: -200px;
        }
    }
}
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>
