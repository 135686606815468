<template>
    <div class="forms-password-input">
        <b-input
            class="input"
            :name="name"
            v-bind="$attrs"
            :required="required"
            :state="state"
            :type="inputType"
            :value="value"
            @change="handleChange"
        />

        <icon class="icon" :icon="icon" @click="isVisible = !isVisible" />
    </div>
</template>

<style lang="scss" scoped>
@import "variables";

.forms-password-input {
    display: flex;
    align-items: center;
    position: relative;

    .input {
        padding-right: 32px;
    }

    .icon {
        position: absolute;
        right: 8px;
        color: $text-muted;
    }
}
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    inheritAttrs: false,
    props: {
        name: {
            required: true,
            type: String,
        },
        required: Boolean,
        state: {
            default: true,
            type: Boolean,
        },
        value: {
            default: "",
            type: String,
        },
    },
    data() {
        return {
            isVisible: false,
        };
    },
    computed: {
        icon(): string {
            return this.isVisible ? "eye-slash" : "eye";
        },
        inputType(): string {
            return this.isVisible ? "text" : "password";
        },
    },
    methods: {
        handleChange(value: string) {
            this.$emit("input", value);
        },
    },
});
</script>
