<template>
    <b-modal
        v-bind="attrs"
        centered
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        @hidden="handleHidden"
        @ok="handleOk"
        @show="handleShow"
    >
        <template v-for="slot in Object.keys($scopedSlots)" #[slot]="scope">
            <slot v-if="open" :name="slot" v-bind="scope" />
        </template>
    </b-modal>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    inheritAttrs: false,
    props: {
        hideFooter: Boolean,
        hideHeader: Boolean,
        open: {
            required: true,
            type: Boolean,
        },
    },
    computed: {
        attrs() {
            // set default attribute values
            return {
                bodyBgVariant: "light",
                size: "md",
                ...this.$attrs,
                visible: this.open,
            };
        },
    },
    methods: {
        handleHidden() {
            this.$emit("close");
        },
        handleShow() {
            this.$emit("reset");
        },
        handleOk() {
            this.$emit("ok");
        },
    },
});
</script>
