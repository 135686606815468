import { merge } from "lodash/fp";
import moment from "moment";
import Vue from "vue";
import VueI18n, { I18nOptions } from "vue-i18n";

import publicTranslations from "@apps/public/i18n/fr.json";

import sharedTranslations from "./shared/fr.json";

import { getTranslations } from ".";

Vue.use(VueI18n);

const fallbackLocale = "fr";

class VueI18nInstance {
    public app: string | null = null;
    public i18n: VueI18n;

    constructor(options: I18nOptions = {}) {
        // initiate a Vue I18n instance
        this.i18n = new VueI18n(options);

        // by default load fr translations for public app (to remove when all pages are SPAs)
        // this is needed for `window.i18n` bindings on blade pages
        if (!options.locale) {
            const messages = merge(publicTranslations, sharedTranslations);

            this.i18n.setLocaleMessage("fr", messages);
            this.i18n.locale = "fr";
        }
    }

    async init(app: string, locale: string) {
        this.app = app;

        // get messages for app and locale
        const messages = await getTranslations(app, locale);

        // set messages as available and set new locale
        this.i18n.setLocaleMessage(locale, messages[locale]);
        this.i18n.locale = locale;
        moment.locale(locale);

        // get fallback locale messages if necessary
        if (locale !== fallbackLocale) {
            const existingFallbackMessages = this.i18n.getLocaleMessage(fallbackLocale);

            // current app translations don't have fallbacks, fetch them
            if (!existingFallbackMessages[app]) {
                // get messages for app and fallback locale
                const messages = await getTranslations(app, fallbackLocale);

                // set fallback messages as available
                this.i18n.setLocaleMessage(fallbackLocale, messages[fallbackLocale]);
            }
        }

        return this;
    }

    translate(key: string, params = {}) {
        return this.i18n.t(key, params);
    }

    plural(key: string, count = 1, params = {}) {
        return this.i18n.tc(key, count, params);
    }

    async setLocale(locale: string) {
        if (!this.app) {
            throw new Error("Trying to set locale but there is no app initialized");
        }

        return await this.init(this.app, locale);
    }
}

const vueI18nInstance = new VueI18nInstance({
    fallbackLocale,
    // replace spaces before ' !' etc. with non-breaking spaces
    postTranslation: (str) => {
        if (typeof str === "string") {
            return str.replace(/ (?=[?!:])/, "\xa0");
        }

        return str;
    },
});

export const translate = (key: string, params = {}) => {
    return vueI18nInstance.translate(key, params);
};

export const plural = (key: string, count = 1, params = {}) => {
    return vueI18nInstance.plural(key, count, params);
};

export default vueI18nInstance;
