<template>
    <div class="panel-border" :class="{ 'bg-white': white }">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
@import "variables";

.panel-border {
    padding: 16px;

    & + .panel-border {
        border-top: solid 1px $color-border;
    }
}
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        white: {
            default: false,
            type: Boolean,
        },
    },
});
</script>
