import DOMPurify from "dompurify";
import * as marked from "marked";
import TurndownService from "turndown";

// transform markdown in safe HTML
export const toHTML = (markdown: string): string => {
    if (!markdown) {
        return "";
    }

    /**
     * Adds `target="_blank" to links in markdown
     * https://github.com/markedjs/marked/issues/655#issuecomment-383226346
     */
    const renderer = new marked.Renderer();
    const linkRenderer = renderer.link;

    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);

        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
    };

    // from markdown to HTML
    const parsed = marked.marked(markdown, {
        breaks: true,
        renderer,
    });

    // sanitize HTML
    return DOMPurify.sanitize(parsed, { ADD_ATTR: ["target"] }).trim();
};

// transform markdown in text (without markup)
export const strip = (markdown: string): string => {
    const html = toHTML(markdown);
    const htmlWithSpaces = html
        // add a space after newline/paragraph ending, so stripping the tags won't make two sentences without space in between
        .replace(/(<br ?\/?>|<\/p>)/gi, "$1 ")
        // remove trailing whitespace if we added one at the end
        .replace(/ $/, "");

    return DOMPurify.sanitize(htmlWithSpaces, { ALLOWED_TAGS: [] });
};

// transform HTML to Markdown
export const toMarkdown = (html: string): string => {
    return new TurndownService({ emDelimiter: "*" })
        .addRule("strikethrough", {
            filter: ["del", "s"],
            replacement: (content: string) => {
                return `~~${content}~~`;
            },
        })
        .turndown(html);
};
