import Vue from "vue";

import { Company, CompanyFeature } from "@store/resources";
import companyFeatures from "@utils/companyFeatures";

import ContextPlugin from "./context";

const { areCompanyFeaturesActivated } = companyFeatures();

class CompanyFeaturePlugin {
    constructor(
        private readonly $context: ContextPlugin,
        private readonly currentCompany: Company | null = null
    ) {}

    static install(vue: typeof Vue): void {
        vue.mixin({ beforeCreate: this.init });
    }

    static init(
        this: Vue & {
            // plugin can be passed itself as option when adding plugin to Vue
            $options: {
                companyFeature: CompanyFeaturePlugin;
            };
        }
    ): void {
        const options = this.$options;

        // injection in root
        if (options.companyFeature) {
            this.$companyFeature = options.companyFeature;
        }
        // get from parent
        else if (options.parent && options.parent.$companyFeature) {
            this.$companyFeature = options.parent.$companyFeature;
        }
    }
    isCompanyEnabled(companyFeature: CompanyFeature | CompanyFeature[]): boolean {
        // this method doesn't make sense outside of the employee/Company context, but is sometimes called by other contexts in shared Component. To avoid breaking, we handle all contexts in the method.

        if (this.$context.isAdminContext()) {
            return true;
        }

        if (this.$context.isAssociationContext()) {
            return true;
        }

        if (this.$context.isPublicContext()) {
            return true;
        }

        if (!this.currentCompany) {
            return false;
        }

        return areCompanyFeaturesActivated(
            this.currentCompany.company_enabled_features,
            Array.isArray(companyFeature) ? companyFeature : [companyFeature]
        );
    }

    isVendrediAllowed(companyFeature: CompanyFeature | CompanyFeature[]): boolean {
        if (this.$context.isAdminContext()) {
            return true;
        }

        if (this.$context.isAssociationContext()) {
            return true;
        }

        if (this.$context.isPublicContext()) {
            return true;
        }

        if (!this.currentCompany) {
            return false;
        }

        return areCompanyFeaturesActivated(
            this.currentCompany.vendredi_allowed_features,
            Array.isArray(companyFeature) ? companyFeature : [companyFeature]
        );
    }

    isCompletelyActivated(companyFeature: CompanyFeature | CompanyFeature[]): boolean {
        if (this.$context.isAdminContext()) {
            return true;
        }

        if (this.$context.isAssociationContext()) {
            return true;
        }

        if (!this.currentCompany) {
            return false;
        }

        return areCompanyFeaturesActivated(
            this.currentCompany.completely_activated_features,
            Array.isArray(companyFeature) ? companyFeature : [companyFeature]
        );
    }
}

export default CompanyFeaturePlugin;
