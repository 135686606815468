<template>
    <modal
        v-bind="$attrs"
        centered
        content-class="employee-modal-content"
        :header-class="['modal-header', `modal-header-${variant}`]"
        :open="open"
        v-on="$listeners"
    >
        <template #modal-header="scope">
            <slot name="modal-header" v-bind="scope" />
        </template>

        <template #default>
            <div class="half-circle" :class="[`half-circle-${variant}`]" />

            <slot name="default" />
        </template>

        <template #modal-footer="scope">
            <slot name="modal-footer" v-bind="scope" />
        </template>
    </modal>
</template>

<style lang="scss">
@import "variables";

.employee-modal-content {
    .modal-header {
        border-bottom: 0;
        color: white;
        text-align: center;

        &-primary {
            background-color: var(--theme-primary-dark);

            h2 {
                color: $color-white;
            }
        }

        &-secondary {
            background-color: $color-secondary-basic;
        }
    }

    .half-circle {
        width: 100%;
        height: 100px;
        border-radius: 0 0 100% 100%;
        margin-top: -50px;

        &-primary {
            background-color: var(--theme-primary-dark);
        }

        &-secondary {
            background-color: $color-secondary-basic;
        }
    }

    .illustration {
        margin-top: -50px;
        margin-bottom: 30px;
    }

    .modal-body {
        padding-top: 0 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        overflow: hidden;
    }
}
</style>

<script lang="ts">
import Vue, { PropType } from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        open: {
            required: true,
            type: Boolean,
        },
        variant: {
            default: "primary",
            type: String as PropType<"primary" | "secondary">,
        },
    },
});
</script>
