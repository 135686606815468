<template>
    <panel-block white>
        <div class="d-flex">
            <div
                v-for="tab in tabs"
                :key="tab.key"
                class="mb-3 panel-tab text-truncate"
                :class="{
                    active: isSelectedTab(tab.key),
                }"
                :style="{
                    // make sure a tab with a long text does not take all the space
                    'max-width': `${100 / tabs.length}%`,
                }"
                @click="changeTab(tab.key)"
            >
                {{ tab.label }}
            </div>
        </div>

        <div v-for="tab in tabs" :key="tab.key">
            <slot v-if="isSelectedTab(tab.key)" :name="tab.key" />
        </div>
    </panel-block>
</template>

<style lang="scss" scoped>
@import "variables";

.panel-tab {
    cursor: pointer;
    color: $text-muted;
    padding-bottom: 5px;

    &:not(:first-child) {
        margin-left: 24px;
    }

    &.active {
        border-bottom: 2px solid $color-primary-basic;
        color: $color-primary-basic;
        font-weight: bold;
    }
}
</style>

<script lang="ts">
import Vue, { PropType } from "vue";

export type PanelTab = {
    key: string;
    label: string;
};

export default Vue.extend({
    props: {
        tabs: {
            required: true,
            type: Array as PropType<PanelTab[]>,
        },
    },
    data() {
        return {
            currentTab: "" as PanelTab["key"],
        };
    },
    mounted() {
        this.changeTab();
    },
    methods: {
        changeTab(key: PanelTab["key"] = ""): void {
            if (!key) {
                this.currentTab = this.tabs[0].key;
            } else {
                this.currentTab = key;
            }
        },
        isSelectedTab(key: PanelTab["key"]): boolean {
            return this.currentTab === key;
        },
    },
});
</script>
