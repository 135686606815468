import { render, staticRenderFns } from "./vue-tags-input.vue?vue&type=template&id=d5d786ce&scoped=true"
import script from "./vue-tags-input.ts?vue&type=script&lang=ts&external"
export * from "./vue-tags-input.ts?vue&type=script&lang=ts&external"
import style0 from "./vue-tags-input.scss?vue&type=style&index=0&id=d5d786ce&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5d786ce",
  null
  
)

export default component.exports