import { keyBy, sortBy } from "lodash/fp";

import { translate } from "@i18n/instance";

const getSkills = () => {
    return sortBy(["label"], <const>[
        {
            icon: "graduation-cap",
            label: translate("shared.utils.skills.academic"),
            value: "SKILL_ACADEMIC",
        },
        {
            icon: "th-list",
            label: translate("shared.utils.skills.administrativeAccounting"),
            value: "SKILL_ADMINISTRATIVE_ACCOUNTING",
        },
        {
            icon: "user-tie",
            label: translate("shared.utils.skills.cvHelp"),
            value: "SKILL_CV_HELP",
        },
        {
            icon: "palette",
            label: translate("shared.utils.skills.designPhoto"),
            value: "SKILL_DESIGN_PHOTO",
        },
        {
            icon: "laptop-code",
            label: translate("shared.utils.skills.digital"),
            value: "SKILL_DIGITAL",
        },
        {
            icon: "comments",
            label: translate("shared.utils.skills.event"),
            value: "SKILL_EVENT",
        },
        {
            icon: "users-class",
            label: translate("shared.utils.skills.humanResource"),
            value: "SKILL_HUMAN_RESOURCE",
        },
        {
            icon: "chalkboard-teacher",
            label: translate("shared.utils.skills.jobPresentation"),
            value: "SKILL_JOB_PRESENTATION",
        },
        {
            icon: "balance-scale-left",
            label: translate("shared.utils.skills.legal"),
            value: "SKILL_LEGAL",
        },
        {
            icon: "dolly",
            label: translate("shared.utils.skills.logistic"),
            value: "SKILL_LOGISTIC",
        },
        {
            icon: "hammer",
            label: translate("shared.utils.skills.manualWork"),
            value: "SKILL_MANUAL_WORK",
        },
        {
            icon: "bullseye-arrow",
            label: translate("shared.utils.skills.marketing"),
            value: "SKILL_MARKETING",
        },
        {
            icon: "comment-dollar",
            label: translate("shared.utils.skills.partnership"),
            value: "SKILL_PARTNERSHIP",
        },
        {
            icon: "hand-holding-box",
            label: translate("shared.utils.skills.popularAid"),
            value: "SKILL_POPULAR_AID",
        },
        {
            icon: "running",
            label: translate("shared.utils.skills.sport"),
            value: "SKILL_SPORT",
        },
        {
            icon: "language",
            label: translate("shared.utils.skills.translate"),
            value: "SKILL_TRANSLATE",
        },
    ]);
};

export type Skill = ReturnType<typeof getSkills>[number]["value"];

const getters = () => {
    const skills = getSkills();

    return {
        skillByValue: keyBy("value", skills),
        skills,
    };
};

export default getters;
