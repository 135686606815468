import { keyBy } from "lodash/fp";

import { translate } from "@i18n/instance";

export enum Role {
    Ambassador = "COMPANY_AMBASSADOR",
    Association_admin = "ASSOCIATION_ADMIN",
    Association_member = "ASSOCIATION_MEMBER",
    Collaborator = "COMPANY_COLLABORATOR",
    CompanyAdmin = "COMPANY_ADMIN",
    HoldingAdmin = "COMPANY_HOLDING_ADMIN",
}

const getAssociationRoles = () => {
    return <const>[
        {
            label: translate("shared.utils.roles.association.admin"),
            value: Role.Association_admin,
        },
        {
            label: translate("shared.utils.roles.association.member"),
            value: Role.Association_member,
        },
    ];
};

const getCompanyRoles = () => {
    return <const>[
        {
            label: translate("shared.utils.roles.company.collaborator"),
            value: Role.Collaborator,
        },
        {
            label: translate("shared.utils.roles.company.ambassador"),
            value: Role.Ambassador,
        },
        {
            label: translate("shared.utils.roles.company.admin"),
            value: Role.CompanyAdmin,
        },
        {
            label: translate("shared.utils.roles.company.adminHolding"),
            value: Role.HoldingAdmin,
        },
    ];
};

export type AssociationRole = ReturnType<typeof getAssociationRoles>[number]["value"];

export type CompanyRole = ReturnType<typeof getCompanyRoles>[number]["value"];

const getters = () => {
    const associationRoles = getAssociationRoles();
    const companyRoles = getCompanyRoles();

    return {
        associationRoleByValue: keyBy("value", associationRoles),
        associationRoles,
        companyRoleByValue: keyBy("value", companyRoles),
        companyRoles,
    };
};

export default getters;
