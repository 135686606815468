<template>
    <div
        class="image-slideshow"
        :class="{ 'd-block': isOpen }"
        @click="handleClick"
        @touchend="handleTouchEnd"
        @touchstart="handleTouchStart"
    >
        <transition name="lightbox-transition">
            <b-img id="image-lightbox" :key="currentIndex" ref="image" class="image" :src="src" />
        </transition>
        <span class="counter"> {{ currentIndex + 1 }} / {{ medias.length }}</span>
        <b-button
            v-if="!single"
            class="d-md-block d-none previous-button"
            size="lg"
            variant="secondary"
            @click="handlePreviousButtonClick"
        >
            <Icon icon="chevron-left" />
        </b-button>
        <b-button
            v-if="!single"
            class="d-md-block d-none next-button"
            size="lg"
            variant="secondary"
            @click="handleNextButtonClick"
        >
            <Icon icon="chevron-right" />
        </b-button>
        <b-button class="close-button" size="lg" variant="secondary" @click="handleClose">
            <Icon icon="times" />
        </b-button>
    </div>
</template>

<style lang="scss" scoped>
@import "mixins", "variables";

.lightbox-transition-enter-active,
.lightbox-transition-leave-active {
    transition: opacity 0.5s;
}

.lightbox-transition-enter {
    opacity: 0;
}
.lightbox-transition-leave-active {
    opacity: 0;
}
.lightbox-transition-enter-active {
    transition-delay: 0.3s;
}

.image-slideshow {
    background-color: $color-overlay;
    cursor: pointer;
    bottom: 0;
    display: none;

    text-align: center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1031;
    width: 100vw;
    height: 100vh;
    .image {
        vertical-align: middle;
        cursor: zoom-in;
        max-height: 80vh;
        max-width: 80vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        @include media-below("md") {
            max-width: calc(100vw - 2rem);
        }
    }
    .counter {
        transform: translateX(-50%);
        margin-left: 30px;
        font-size: x-large;
        color: white;
    }

    .close-button,
    .next-button,
    .previous-button {
        align-items: center;
        display: flex;
        height: 3rem;
        justify-content: center;
        padding: 0;
        position: absolute;
        width: 3rem;
        z-index: 1;
    }
    .close-button {
        right: 1rem;
        top: 1rem;
    }
    .next-button,
    .previous-button {
        top: 50%;
        transform: translateY(-50%);
    }
    .next-button {
        right: 1rem;
    }
    .previous-button {
        left: 1rem;
    }
}
</style>

<script lang="ts">
import Vue, { nextTick } from "vue";

import { disableScrollBody, enableScrollBody } from "@utils/scroll";

const escapeKey = "Escape";
const leftArrowKey = "ArrowLeft";
const rightArrowKey = "ArrowRight";

export default Vue.extend({
    data() {
        return {
            medias: [],
            currentIndex: 0,
            isOpen: false,
            touchStartScreenX: 0,
            isZoom: false,
        };
    },
    mounted() {
        window.addEventListener("keydown", this.handleWindowKeydown);
        this.$root.$on("lightbox:open", this.handleOpen);

        this.$root.$on("lightbox:close", this.handleClose);
    },
    beforeDestroy() {
        this.$root.$off("lightbox:open", this.handleOpen);

        this.$root.$off("lightbox:close", this.handleClose);
    },
    computed: {
        src(): string {
            return this.medias[this.currentIndex];
        },
        single(): boolean {
            return this.medias.length === 1;
        },
    },
    methods: {
        async handleClose(): Promise<void> {
            this.currentIndex = 0;
            this.medias = [];
            this.isOpen = false;
            await nextTick();
            enableScrollBody();
        },
        async removeScroll(): Promise<void> {
            await nextTick();
            disableScrollBody();
        },
        async handleOpen(data?: object | null): Promise<void> {
            this.medias = data.medias;
            this.currentIndex = data.index;
            await nextTick();
            this.isOpen = true;
            this.removeScroll();
        },
        handleClick(event: PointerEvent): void {
            if (event.target === this.$refs.image) {
                this.isZoom ? this.deZoom() : this.zoom();

                return;
            }

            this.handleClose();
        },
        handleNextButtonClick(event: PointerEvent): void {
            event.stopPropagation();
            this.next();
        },
        handlePreviousButtonClick(event: PointerEvent): void {
            event.stopPropagation();
            this.previous();
        },
        handleTouchStart(event: TouchEvent): void {
            this.touchStartScreenX = event.changedTouches[0].screenX;
        },
        handleTouchEnd(event: TouchEvent): void {
            // Swipe from right to left
            if (this.touchStartScreenX >= event.changedTouches[0].screenX) {
                this.next();
            } else {
                this.previous();
            }

            this.touchStartScreenX = 0;
        },
        handleWindowKeydown(event: KeyboardEvent): void {
            switch (event.key) {
                case escapeKey:
                    this.handleClose();
                    break;
                case leftArrowKey:
                    this.previous();
                    break;
                case rightArrowKey:
                    this.next();
                    break;
            }
        },
        next(): void {
            let nextIndex = this.currentIndex + 1;

            if (nextIndex >= this.medias.length) {
                nextIndex = 0;
            }

            this.currentIndex = nextIndex;
        },
        previous(): void {
            let previousIndex = this.currentIndex - 1;

            if (previousIndex < 0) {
                previousIndex = this.medias.length - 1;
            }

            this.currentIndex = previousIndex;
        },
        deZoom(): void {
            document.getElementById("image-lightbox").style.height = "auto";
            document.getElementById("image-lightbox").style.cursor = "zoom-in";
            document.getElementById("image-lightbox").style.maxHeight = "80vh";
            document.getElementById("image-lightbox").style.maxWidth = "80vw";
            this.isZoom = false;
        },
        zoom(): void {
            document.getElementById("image-lightbox").style.height = "100%";
            document.getElementById("image-lightbox").style.cursor = "zoom-out";
            document.getElementById("image-lightbox").style.maxHeight = "100vh";
            document.getElementById("image-lightbox").style.maxWidth = "100vw";
            this.isZoom = true;
        },
    },
});
</script>
