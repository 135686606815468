import { Activity, Project, ProjectCall, Timeslot } from "@store/resources";
import { AssociationLight } from "@store/resources/Association";
import { CatalogHighlightLight } from "@store/resources/CatalogHighlight";
import { CompanyLight } from "@store/resources/Company";
import { EngagementLight } from "@store/resources/Engagement";
import { LevelLight } from "@store/resources/Level";
import { MissionLight } from "@store/resources/Mission";
import { ProgramLight } from "@store/resources/Program";
import Publisher, { PublisherAssociation, PublisherCompany } from "@store/resources/Publisher";
import { QuestLight } from "@store/resources/Quest";
import { TaxExemptionLight } from "@store/resources/TaxExemption";
import { UserLight } from "@store/resources/User";
import { Link } from "@types";
import ExternalRouter from "@utils/externalRouter";
import ContextPlugin from "client/plugins/context";
import PermissionPlugin from "client/plugins/permission";

import { Role } from "./roles";

type TableRoute = (plugins: { $context: ContextPlugin; $permission: PermissionPlugin }, item: any) => Link | undefined;

export type SharedRoute =
    | "activity"
    | "association"
    | "catalogHighlight"
    | "company"
    | "engagement"
    | "mission"
    | "level"
    | "program"
    | "project"
    | "projectCall"
    | "publisher"
    | "quest"
    | "subscriber"
    | "taxExemption"
    | "user"
    | "volunteer";

export const routes: {
    [name in SharedRoute]: TableRoute;
} = {
    activity: (plugins, activity: Activity): Link | undefined => {
        if (plugins.$context.isEmployeeContext()) {
            if (activity.activity_type === "ACTIVITY_TYPE_ENGAGEMENT") {
                return routes.mission(plugins, activity.mission);
            }

            return DEFAULT_LINK;
        }

        return routes.user(plugins, activity.employee);
    },
    association: (
        { $context, $permission },
        association: AssociationLight | PublisherAssociation
    ): Link | undefined => {
        if ($context.isCompanyContext()) {
            if ($permission.is([Role.CompanyAdmin, Role.HoldingAdmin])) {
                return {
                    is: "router-link",
                    to: {
                        name: "associations.show",
                        params: { associationSlug: association.slug },
                    },
                };
            }

            return;
        }

        if ($context.isEmployeeContext()) {
            return {
                is: "router-link",
                to: {
                    name: "associations.show",
                    params: { associationSlug: association.slug },
                },
            };
        }

        if ($context.isAdminContext()) {
            return {
                is: "router-link",
                to: {
                    name: "association",
                    params: { associationSlug: association.slug },
                },
            };
        }

        if ($context.isPublicContext()) {
            const href = ExternalRouter.url("public.association", {
                association: { slug: association.slug },
            });

            return {
                href,
                is: "a",
            };
        }
    },
    catalogHighlight: ({ $context }, catalogHighlight: CatalogHighlightLight): Link | undefined => {
        if ($context.isAdminContext()) {
            return {
                is: "router-link",
                to: {
                    name: "catalogHighlights.show",
                    params: { catalogHighlightId: String(catalogHighlight.id) },
                },
            };
        }
    },
    company: ({ $context }, company: CompanyLight | PublisherCompany): Link | undefined => {
        if ($context.isAssociationContext()) {
            return {
                is: "router-link",
                to: {
                    name: "companies.show",
                    params: { companySlug: company.slug },
                },
            };
        }

        if ($context.isAdminContext()) {
            return {
                is: "router-link",
                to: {
                    name: "company",
                    params: { companySlug: company.slug },
                },
            };
        }
    },
    engagement: ({ $context }, engagement: EngagementLight): Link | undefined => {
        if ($context.isAssociationContext()) {
            return {
                is: "router-link",
                to: {
                    name: "messenger",
                    params: {
                        engagementId: String(engagement.id),
                    },
                },
            };
        }

        if ($context.isCompanyContext()) {
            return {
                is: "router-link",
                to: {
                    name: "users.engagement",
                    params: {
                        engagementId: String(engagement.id),
                        userId: String(engagement.employee_id),
                    },
                },
            };
        }

        if ($context.isEmployeeContext()) {
            return {
                is: "router-link",
                to: {
                    name: "engagement.show",
                    params: {
                        engagementId: String(engagement.id),
                    },
                },
            };
        }

        if ($context.isAdminContext()) {
            if (engagement.publisher.type === "PUBLISHER_TYPE_ASSOCIATION") {
                return {
                    href: ExternalRouter.url("association.messenger", {
                        association: engagement.publisher,
                        engagement: engagement,
                    }),
                    is: "a",
                    target: "_blank",
                };
            }

            if (engagement.publisher.type === "PUBLISHER_TYPE_COMPANY") {
                return {
                    href: ExternalRouter.url("company.messenger", {
                        company: engagement.publisher,
                        engagement: engagement,
                    }),
                    is: "a",
                    target: "_blank",
                };
            }
        }
    },
    level: ({ $context }, level: LevelLight): Link | undefined => {
        if ($context.isCompanyContext()) {
            if (level.challenge) {
                return {
                    is: "router-link",
                    to: {
                        name: "challenges.update",
                        params: {
                            challengeId: String(level.challenge.id),
                            questId: String(level.quest_id),
                        },
                    },
                };
            }

            return {
                is: "router-link",
                to: {
                    name: "levels.update",
                    params: {
                        levelId: String(level.id),
                        questId: String(level.quest_id),
                    },
                },
            };
        }

        if ($context.isAdminContext()) {
            if (level.challenge) {
                return {
                    is: "router-link",
                    to: {
                        name: "challenges.update",
                        params: {
                            challengeId: String(level.challenge.id),
                            questId: String(level.quest_id),
                        },
                    },
                };
            }

            return {
                is: "router-link",
                to: {
                    name: "levels.update",
                    params: {
                        levelId: String(level.id),
                        questId: String(level.quest_id),
                    },
                },
            };
        }
    },
    mission: ({ $context, $permission }, mission: MissionLight): Link | undefined => {
        if ($context.isAdminContext() && mission.publisher.type === "PUBLISHER_TYPE_ADMIN") {
            return {
                is: "router-link",
                to: {
                    name: "missions.update",
                    params: { missionId: String(mission.id) },
                },
            };
        }

        if ($context.isAssociationContext()) {
            if (mission.publisher.type === "PUBLISHER_TYPE_ASSOCIATION") {
                return {
                    is: "router-link",
                    to: {
                        name: "missions.show",
                        params: { missionId: String(mission.id) },
                    },
                };
            }
        }

        if ($context.isCompanyContext()) {
            if (
                $permission.is([
                    Role.CompanyAdmin,
                    Role.HoldingAdmin,
                    {
                        entity_id: mission.booked_for_entity_id || undefined,
                        publisher_id: mission.publisher_id,
                        role: Role.Ambassador,
                    },
                ])
            ) {
                return {
                    is: "router-link",
                    to: {
                        name: "missions.show",
                        params: { missionId: String(mission.id) },
                    },
                };
            }

            return {
                href: ExternalRouter.url("employee.mission", { mission }),
                is: "a",
            };
        }

        if ($context.isEmployeeContext()) {
            return {
                is: "router-link",
                to: {
                    name: "missions.show",
                    params: {
                        missionId: String(mission.id),
                        missionSlug: mission.slug,
                    },
                },
            };
        }

        if ($context.isAdminContext()) {
            if (mission.publisher.type === "PUBLISHER_TYPE_ASSOCIATION") {
                return {
                    href: ExternalRouter.url("association.mission", { mission }),
                    is: "a",
                };
            }

            if (mission.publisher.type === "PUBLISHER_TYPE_COMPANY") {
                return {
                    href: ExternalRouter.url("company.mission", { mission }),
                    is: "a",
                };
            }
        }

        if ($context.isPublicContext()) {
            if (mission.publisher.type === "PUBLISHER_TYPE_ASSOCIATION") {
                return {
                    href: ExternalRouter.url("public.mission", { mission }),
                    is: "a",
                };
            }

            if (mission.publisher.type === "PUBLISHER_TYPE_COMPANY") {
                return {
                    href: ExternalRouter.url("employee.mission", {
                        company: { slug: mission.publisher.slug },
                        mission,
                    }),
                    is: "a",
                };
            }
        }
    },
    volunteer: ({ $context, $permission }, timeslot: Timeslot): Link | undefined => {
        if ($context.isCompanyContext()) {
            if ($permission.is([Role.CompanyAdmin, Role.HoldingAdmin, Role.Ambassador])) {
                return {
                    is: "router-link",
                    to: {
                        name: "missions.show",
                        params: { missionId: String(timeslot.mission_id) },
                        query: {
                            bar_tab: "volunteers",
                            timeslot: String(timeslot.id),
                        },
                    },
                };
            }
        }

        if ($context.isAssociationContext()) {
            return {
                is: "router-link",
                to: {
                    name: "missions.show",
                    params: { missionId: String(timeslot.mission_id) },
                    query: {
                        bar_tab: "volunteers",
                        timeslot: String(timeslot.id),
                    },
                },
            };
        }
    },
    subscriber: ({ $context, $permission }, timeslot: Timeslot): Link | undefined => {
        if ($context.isCompanyContext()) {
            if ($permission.is([Role.CompanyAdmin, Role.HoldingAdmin, Role.Ambassador])) {
                return {
                    is: "router-link",
                    to: {
                        name: "missions.show",
                        params: { missionId: String(timeslot.mission_id) },
                        query: {
                            bar_tab: "subscribers",
                            timeslot: String(timeslot.id),
                        },
                    },
                };
            }
        }

        if ($context.isAssociationContext()) {
            return {
                is: "router-link",
                to: {
                    name: "missions.show",
                    params: { missionId: String(timeslot.mission_id) },
                    query: {
                        bar_tab: "subscribers",
                        timeslot: String(timeslot.id),
                    },
                },
            };
        }
    },
    program: ({ $context }, program: ProgramLight): Link | undefined => {
        if ($context.isCompanyContext()) {
            return {
                is: "router-link",
                to: {
                    name: "programs.show",
                    params: {
                        programId: String(program.id),
                    },
                },
            };
        }

        if ($context.isEmployeeContext()) {
            return {
                is: "router-link",
                to: {
                    name: "programs.show",
                    params: {
                        programId: String(program.id),
                        programSlug: program.slug,
                    },
                },
            };
        }

        if ($context.isAdminContext()) {
            if (program.publisher.type === "PUBLISHER_TYPE_ADMIN") {
                return {
                    is: "router-link",
                    to: {
                        name: "programs.show",
                        params: { programId: String(program.id) },
                    },
                };
            }

            if (program.publisher.type === "PUBLISHER_TYPE_COMPANY") {
                return {
                    href: ExternalRouter.url("company.program", { program }),
                    is: "a",
                };
            }
        }
    },
    project: ({ $context }, project: Project): Link | undefined => {
        if ($context.isCompanyContext()) {
            return {
                is: "router-link",
                to: {
                    name: "project",
                    params: { projectCallId: String(project.project_call_id), projectId: String(project.id) },
                },
            };
        }
    },
    projectCall: ({ $context }, projectCall: ProjectCall): Link | undefined => {
        if ($context.isCompanyContext()) {
            return {
                is: "router-link",
                to: {
                    name: "projectCalls.show",
                    params: { projectCallId: String(projectCall.id) },
                },
            };
        }
    },
    publisher: (plugins, publisher: Publisher): Link | undefined => {
        if (publisher.type === "PUBLISHER_TYPE_ASSOCIATION") {
            return routes.association(plugins, publisher);
        }

        if (publisher.type === "PUBLISHER_TYPE_COMPANY") {
            return routes.company(plugins, publisher);
        }
    },
    quest: ({ $context }, quest: QuestLight): Link | undefined => {
        if ($context.isCompanyContext()) {
            return {
                is: "router-link",
                to: {
                    name: "quests.show",
                    params: { questId: String(quest.id) },
                },
            };
        }

        if ($context.isEmployeeContext()) {
            return {
                is: "router-link",
                to: {
                    name: "quests.show",
                    params: {
                        questId: String(quest.id),
                        questSlug: quest.slug,
                    },
                },
            };
        }

        if ($context.isAdminContext()) {
            if (quest.publisher.type === "PUBLISHER_TYPE_ADMIN") {
                return {
                    is: "router-link",
                    to: {
                        name: "quests.show",
                        params: { questId: String(quest.id) },
                    },
                };
            }

            if (quest.publisher.type === "PUBLISHER_TYPE_COMPANY") {
                return {
                    href: ExternalRouter.url("company.quest.edit", { quest }),
                    is: "a",
                };
            }
        }
    },
    taxExemption: ({ $context }, taxExemption: TaxExemptionLight): Link | undefined => {
        if ($context.isAdminContext()) {
            return {
                href: ExternalRouter.url("admin.taxExemption.show", { taxExemption }),
                is: "a",
            };
        }
    },
    user: ({ $context, $permission }, user: UserLight): Link | undefined => {
        if ($context.isCompanyContext()) {
            if ($permission.is([Role.CompanyAdmin, Role.HoldingAdmin])) {
                return {
                    is: "router-link",
                    to: {
                        name: "users.show",
                        params: { userId: String(user.id) },
                    },
                };
            }

            return;
        }

        if ($context.isAdminContext()) {
            return {
                is: "router-link",
                to: {
                    name: "user",
                    params: { userId: String(user.id) },
                },
            };
        }
    },
};

const DEFAULT_LINK: Link = { is: "div" };

export type SharedRouterOptions = {
    target?: "_blank" | "_self";
};

/**
 * Helps Vue link generation to a page, no matter from which context
 *
 * Examples:
 * => SharedRouter.link(this.$context, "mission", this.mission)
 * ===> {
 *   is: "router-link",
 *   to: {
 *     name: "missions.show",
 *     params: { missionId: String(mission.id) },
 *   },
 * }
 */
const SharedRouter = {
    link(
        $context: ContextPlugin,
        $permission: PermissionPlugin,
        name: SharedRoute,
        item: any,
        options: SharedRouterOptions = {}
    ): Link {
        const route = routes[name];

        // check we have a route for name
        if (!route) {
            console.error(`SharedRouter route with name "${name}" does not exist`);

            return {
                ...DEFAULT_LINK,
                ...options,
            };
        }

        return {
            ...(route({ $context, $permission }, item) || DEFAULT_LINK),
            ...options,
        };
    },
};

export default SharedRouter;
