export const disableScrollBody = () => {
    document.body.classList.add("body-not-scrollable");
    const layout_html = document.getElementById("layout_html");

    if (layout_html) {
        layout_html.style.overflow = "hidden";
    }
};

export const enableScrollBody = () => {
    document.body.classList.remove("body-not-scrollable");
    const layout_html = document.getElementById("layout_html");

    if (layout_html) {
        layout_html.style.overflow = "auto";
    }
};
