var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"forms-field",class:{
        'w-100': _vm.fullWidth,
    }},[(_vm.label || _vm.$slots.label)?_c('div',{staticClass:"align-items-center d-flex label"},[(_vm.label)?_c('label',{staticClass:"form-label h3"},[_vm._v("\n            "+_vm._s(_vm.label)+"\n            "),(!_vm.required)?_c('small',{staticClass:"font-weight-normal text-muted"},[_vm._v("\n                • "+_vm._s(_vm.$t("shared.layout.optional"))+"\n            ")]):_vm._e(),_vm._v(" "),_vm._t("tooltip")],2):_vm._e(),_vm._v(" "),(_vm.maxLength && _vm.length)?_c('small',{class:{
                'text-danger': _vm.length > _vm.maxLength,
            }},[_vm._v("\n            "+_vm._s(_vm.length)+" / "+_vm._s(_vm.maxLength)+"\n        ")]):_vm._t("label"),_vm._v(" "),_vm._t("label-help")],2):_vm._e(),_vm._v(" "),_c('div',{class:{ 'is-invalid': _vm.error }},[_vm._t("default")],2),_vm._v(" "),(_vm.error)?_c('FieldError',[_vm._v(_vm._s(_vm.error))]):_vm._e(),_vm._v(" "),(_vm.help || _vm.$slots.help)?_c('small',{staticClass:"mt-1 text-muted"},[_vm._t("help",function(){return [_vm._v("\n            "+_vm._s(_vm.help)+"\n        ")]})],2):(_vm.imageHelp)?_c('small',[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.$t('shared.forms.field.imgFormatHelp', {
                    width: _vm.imageHelp.width,
                    height: _vm.imageHelp.height,
                })
            ),expression:"\n                $t('shared.forms.field.imgFormatHelp', {\n                    width: imageHelp.width,\n                    height: imageHelp.height,\n                })\n            "}]}),_vm._v(" "),(_vm.showLibrary)?_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.$t('shared.forms.field.imgLibraryHelp', { url: _vm.helpUrl })),expression:"$t('shared.forms.field.imgLibraryHelp', { url: helpUrl })"}]}):_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.$t('shared.forms.field.imgSettingHelp', { url: _vm.helpUrl })),expression:"$t('shared.forms.field.imgSettingHelp', { url: helpUrl })"}]})]):_vm._e(),_vm._v(" "),_vm._t("below")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }