import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { Association, Company } from "@store/resources";

import { ContextType } from "../plugins/context";

type CompanyContext = { company: Company; type: ContextType.company | ContextType.employee };
type AssociationContext = { association: Association; type: ContextType.association };
type AdminContext = { type: ContextType.admin | ContextType.public };
type PublicContext = { type: ContextType.admin | ContextType.public };

type ApiContext = CompanyContext | AssociationContext | AdminContext | PublicContext;

export const abortController: AbortController = new AbortController();

const Api = {
    instance: null as any as AxiosInstance,

    init(context: ApiContext) {
        const baseUrl = "/api/v1";
        const config: AxiosRequestConfig & { headers: Record<string, string | number> } = {
            baseURL: baseUrl,
            headers: {
                "X-Context-Type": context.type,
                // this allows our laravel backend to detect the call as an AJAX one
                "X-Requested-With": "XMLHttpRequest",
            },
            signal: abortController.signal,
        };

        if (context.type === ContextType.employee || context.type === ContextType.company) {
            config.headers["X-Company-Id"] = context.company.id;
        }

        if (context.type === ContextType.association) {
            config.headers["X-Association-Id"] = context.association.id;
        }

        this.instance = Axios.create(config);

        /*
            Sometimes the session expires but the user still has the tab open, so when they send an ajax call anywhere they get a 401 error. 
            If we detect that we want to reload the page so the user is redirected by the back to the login page we only do it for those contexts 
            where the user has to be logged (so that the back will indeed redirect them). Otherwise if a page from the public context receives a 
            401 for some reason it would reload, but the back wouldn't redirect to the login page since it's public, and now we're in a reloading loop.
        */
        if (
            [ContextType.admin, ContextType.association, ContextType.company, ContextType.employee].includes(
                context.type
            )
        ) {
            this.instance.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if (error?.response?.status === 401) {
                        // reloading the page when the user session expired will redirect the user to
                        // the login page automatically
                        window.location.reload();
                    } else {
                        return Promise.reject(error);
                    }
                }
            );
        }
    },

    get(resource: string, params?: any, config?: Partial<AxiosRequestConfig>): Promise<AxiosResponse> {
        return this.instance.get(resource, { ...config, params });
    },

    post(resource: string, body?: any, config?: Partial<AxiosRequestConfig>): Promise<AxiosResponse> {
        return this.instance.post(resource, body, config);
    },

    put(resource: string, body?: any, config?: Partial<AxiosRequestConfig>): Promise<AxiosResponse> {
        return this.instance.put(resource, body, config);
    },

    delete(resource: string, config?: Partial<AxiosRequestConfig>): Promise<AxiosResponse> {
        return this.instance.delete(resource, config);
    },
};

export default Api;
