<template>
    <div class="callout-message" :class="[`callout-message-${variant}`]">
        <div class="d-flex flex-column justify-content-center w-100">
            <div class="link title">
                <icon class="icon" :icon="icon" />
                <template v-if="title">
                    {{ title }}
                </template>
                <slot name="title" />
            </div>

            <div v-if="$slots['default']" class="link message">
                <slot />
            </div>

            <div v-if="$slots['buttons']" class="buttons">
                <slot name="buttons" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "variables";

.callout-message {
    padding: 16px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .link {
        a {
            color: $headings-color;
            text-decoration: underline;
            font-weight: bold;
        }
    }

    .message {
        margin-top: 8px;
        color: $headings-color;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &-info {
        background-color: $color-info-lightest;
        border: 1px solid $color-info-light;

        .title {
            color: $color-info-dark;
        }
    }

    &-danger {
        background-color: $color-danger-lightest;
        border: 1px solid $color-danger-light;

        .title {
            color: $color-danger-dark;
        }
    }

    &-secondary {
        background-color: $color-grey-lightest;
        border: 1px solid $color-grey-lighter;
    }

    &-success {
        background-color: $color-success-lightest;
        border: 1px solid $color-success-light;

        .title {
            color: $color-success-dark;
        }
    }

    &-warning {
        background-color: $color-warning-lightest;
        border: 1px solid $color-warning-light;

        .title {
            color: $color-warning-dark;
        }
    }

    .title {
        display: flex;
        flex-direction: row;
        font-weight: bold;

        .icon {
            margin-top: 4px;
            margin-right: 8px;
        }
    }

    .buttons {
        margin-top: 16px;
        gap: 16px;
        display: flex;
        flex-direction: row;
    }
}
</style>

<script lang="ts">
import Vue, { PropType } from "vue";

export default Vue.extend({
    props: {
        title: String,
        variant: {
            required: true,
            type: String as PropType<"danger" | "info" | "secondary" | "success" | "warning">,
        },
    },
    computed: {
        icon(): string {
            const { variant } = this;

            if (variant === "danger") {
                return "times-circle";
            }

            if (variant === "info") {
                return "info-circle";
            }

            if (variant === "warning") {
                return "exclamation-circle";
            }

            return "check-circle";
        },
    },
});
</script>
