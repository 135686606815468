import { Id } from "./Api";
import Company from "./Company";
import { ConversationLight } from "./Conversation";
import EngagementFeedback from "./EngagementFeedback";
import { MessageEventContents } from "./Message";
import Mission, { MissionMinimum, MissionPublisher } from "./Mission";
import Survey from "./Survey";
import User, { UserLight } from "./User";

export type EngagementState =
    | "ENGAGEMENT_STATE_VALIDATED"
    | "ENGAGEMENT_STATE_FINISHED"
    | "ENGAGEMENT_STATE_CREATED"
    | "ENGAGEMENT_STATE_CANCELED";

export enum EngagementStep {
    EngagementCanceled = "ENGAGEMENT_STEP_ENGAGEMENT_CANCELED",
    EngagementFinished = "ENGAGEMENT_STEP_ENGAGEMENT_FINISHED",
    EngagementToComplete = "ENGAGEMENT_STEP_ENGAGEMENT_TO_COMPLETE",
    EngagementToUpdateAndFinish = "ENGAGEMENT_STEP_ENGAGEMENT_TO_UPDATE_AND_FINISH",
    ExternalRegistration = "ENGAGEMENT_STEP_EXTERNAL_REGISTRATION",
    MessageSent = "ENGAGEMENT_STEP_MESSAGE_SENT",
    MessageToSend = "ENGAGEMENT_STEP_MESSAGE_TO_SEND",
    SurveyToComplete = "ENGAGEMENT_STEP_SURVEY_TO_COMPLETE",
    TimeslotToSelect = "ENGAGEMENT_STEP_TIMESLOT_TO_SELECT",
    TimeslotToValidate = "ENGAGEMENT_STEP_TIMESLOT_TO_VALIDATE",
}

export type EngagementCanceledBy = Pick<User, "id" | "full_name">;

type EngagementEventMessage = {
    content: MessageEventContents;
    created_at: string;
};

export type EngagementFinishedBy = Pick<User, "id" | "full_name">;

export type EngagementMission = Pick<
    Mission,
    | "id"
    | "slug"
    | "title"
    | "timeslots"
    | "availability_type"
    | "required_availability"
    | "address"
    | "is_credit_decreased"
    | "is_team"
    | "is_remote"
    | "picture_header"
    | "practical_information"
> & {
    publisher: MissionPublisher;
    is_using_timeslot: boolean;
};

export type EngagementManager = Pick<User, "id" | "first_name" | "full_name" | "email" | "phone" | "avatar_url">;

export type EngagementEmployee = Pick<
    User,
    | "id"
    | "first_name"
    | "full_name"
    | "email"
    | "phone"
    | "job_title"
    | "created_at"
    | "credit"
    | "support"
    | "is_supported"
    | "last_activity_at"
    | "last_login_at"
    | "offered_days"
    | "bonus_days"
    | "credit_before_validation"
    | "used_days_before_validation"
    | "used_days_from_finished_engagements"
    | "used_days_from_validated_engagements"
    | "avatar_url"
    | "manager_email"
    | "initial_credit"
    | "company_notes"
    | "is_disabled"
> & {
    company: Pick<Company, "allows_during_worktime" | "has_days_per_employee" | "id" | "slug" | "name" | "logo">;
    manager: Pick<User, "id" | "email" | "full_name"> | null;
};

export type EngagementFeedbackType = Pick<EngagementFeedback, "id" | "question_key" | "rating" | "filled_at">;

export type EngagementSurvey = Pick<
    Survey,
    "id" | "created_at" | "type" | "rating" | "comment" | "filled_at" | "filled_by" | "questions" | "media"
>;

export enum EngagementTimeslotAbsenceTime {
    AFTERNOON = "afternoon",
    DAY = "day",
    MORNING = "morning",
}

export type EngagementTimeslotState =
    | "ENGAGEMENT_TIMESLOT_STATE_CANCELED"
    | "ENGAGEMENT_TIMESLOT_STATE_DRAFT"
    | "ENGAGEMENT_TIMESLOT_STATE_REJECTED"
    | "ENGAGEMENT_TIMESLOT_STATE_VALIDATED"
    | "ENGAGEMENT_TIMESLOT_STATE_TO_VALIDATE";

export enum AbsenceDays {
    Day = 1,
    Half = 0.5,
}

export enum AbsenceTime {
    Afternoon = "afternoon",
    Day = "day",
    Morning = "morning",
}

export type EngagementTimeslot = {
    absence_days: AbsenceDays;
    absence_times: AbsenceTime;
    answered_by: Pick<UserLight, "id" | "email" | "full_name">;
    answered_reason: string | null;
    duration: number;
    end_datetime: string | null;
    id: Id;
    manager_email: string;
    start_datetime: string;
    state: EngagementTimeslotState;
    absence_time: EngagementTimeslotAbsenceTime;
    timeslot_id: Id;
    updated_at: string;
    is_validator_admin: boolean;
};

export type EngagementLight = Pick<Mission, "title"> & {
    id: Id;
    state: EngagementState;
    created_at: string;

    employee_id: Id;
    employee: UserLight;

    canceled_reason: string | null;
    canceled_reason_detail: string | null;

    is_during_worktime: boolean;

    mission: MissionMinimum;

    publisher: MissionPublisher;
    publisher_id: Id;
    beneficiary: MissionPublisher;
    start_date: string | null;
    end_date: string | null;
    surveys: EngagementSurvey[];
    feedbacks: EngagementFeedbackType[];
    engagement_timeslots: EngagementTimeslot[];

    is_credit_decreased: boolean;
    is_using_timeslot: boolean;

    conversation: Pick<ConversationLight, "id" | "last_message_at" | "is_unread">;

    engagement_timeslots_to_validate_count?: number;
    last_activity_at?: string;
    last_finished_engagement_at?: string;
};

type Engagement = Omit<EngagementLight, "employee" | "mission"> &
    Pick<Mission, "title" | "address" | "skills"> & {
        availability: string;
        canceled_by_id: Id | null;
        canceled_by: EngagementCanceledBy | null;
        canceled_at: string | null;
        conversation_id: Id;
        current_step: EngagementStep;
        duration_outside_worktime: number | null;
        employee: EngagementEmployee;
        event_messages: EngagementEventMessage[];
        finished_at: string;
        finished_by: EngagementFinishedBy | null;
        finished_by_id: Id | null;
        has_engagement_agreement: boolean;
        has_future_engagement_timeslot: boolean;
        is_canceled_auto: boolean;
        is_canceled_by_employee: boolean;
        is_canceled_by_publisher: boolean;
        is_registered_on_external_link: boolean;
        manager: EngagementManager;
        manager_id: Id;
        mission_id: Id;
        mission: EngagementMission;
        steps: EngagementStep[];
        validated_at: string | null;

        hide_linked_in_share_button: string[];

        engagement_timeslots: EngagementTimeslot[];

        validation_token: string;
        updated_at: string;
    };

export default Engagement;
