import chroma from "chroma-js";
import ColorThief from "colorthief";
import { saveAs } from "file-saver";
import * as countries from "i18n-iso-countries";
import {
    deburr,
    first,
    flow,
    get,
    isArray,
    isEqual,
    isObject,
    isString,
    kebabCase,
    last,
    lowerCase,
    pickBy,
    sortBy,
    toLower,
    toUpper,
} from "lodash/fp";
import moment, { Moment } from "moment";

import vueI18nInstance, { plural, translate } from "@i18n/instance";
import { Company, Timeslot, User } from "@store/resources";
import { AbsenceDays } from "@store/resources/Engagement";
import { MissionLight } from "@store/resources/Mission";
import languages from "@utils/languages";
import getMissionTypes from "@utils/missionTypes";
import notify from "@utils/notifications";
import getSkills from "@utils/skills";
import getThematics from "@utils/thematics";

const CREDIT_HOURS_PER_DAY = 7;

/**
 * SCROLLING
 */

// scroll page to top
export const scrollToTop = (containerId?: string): void => {
    // if specify a container id, scroll to top in container
    if (containerId) {
        const companyBody = document.getElementById("company-body");

        if (!companyBody) {
            return;
        }

        companyBody.scrollTop = 0;

        return;
    }

    // otherwise scroll to top in window (default behavior)
    window.scrollTo(0, 0);
};

// scroll to component with id
export const scrollToId = (id: string): void => {
    const el = document.getElementById(id);

    if (el) {
        el.scrollIntoView({ behavior: "smooth" });
    }
};

export const hasScrolled = (): boolean => {
    return window.scrollY > 0;
};

export const copyToClipboard = (text: string): void => {
    if (!navigator.clipboard) {
        return;
    }

    navigator.clipboard.writeText(text);
};

/**
 * DATE/TIME FORMATTING
 */

type AcceptedDate = string | Date | Moment | null;

//  converts a db date into a human readable format for date passed, go back a moment in time. X hours ago, yesterday or a date
export const toHumanPastDate = (date?: AcceptedDate): string => {
    if (!date) {
        return "N/A";
    }

    const momentDate = moment(date);
    const now = moment();
    const diffHours = now.diff(momentDate, "hours");

    if (diffHours < 24) {
        return plural("shared.helpers.toHumanPastDate.thereIs", diffHours, {
            hours: diffHours,
        });
    }

    const today = now.startOf("day");
    const momentDay = momentDate.startOf("day");
    const diffDays = today.diff(momentDay, "day");

    if (diffDays >= 1 && diffDays < 2) {
        return translate("shared.helpers.toHumanPastDate.yesterday");
    }

    if (diffDays >= 2 && diffDays < 7) {
        const day = momentDate.format("dddd");

        return translate("shared.helpers.toHumanPastDate.last", {
            day: day,
        });
    }

    return toHumanDate(date);
};

//  converts a db date into a human readable format for date passed, go back a moment in time. X minutes ago, X hours ago or X days ago.
export const toHumanPastDateMHD = (date?: AcceptedDate): string => {
    if (!date) {
        return "-";
    }

    const momentDate = moment(date);
    const now = moment();
    const diffMins = now.diff(momentDate, "minutes");

    if (diffMins < 60) {
        return translate("shared.helpers.toHumanPastDateMHD.thereIsMin", {
            min: diffMins,
        });
    }

    const diffHours = now.diff(momentDate, "hours");

    if (diffHours < 24) {
        return translate("shared.helpers.toHumanPastDateMHD.thereIsHour", {
            hour: diffHours,
        });
    }

    const today = now.startOf("day");
    const diffDays = today.diff(momentDate, "day");

    if (diffDays >= 1) {
        return translate("shared.helpers.toHumanPastDateMHD.thereIsDay", {
            day: diffDays,
        });
    }

    return "-";
};

// converts a db date into a human readable format
export const toHumanDate = (date?: AcceptedDate, isShort = false, showDay = false): string => {
    if (!date) {
        return "N/A";
    }

    const momentDate = moment(date);

    if (!momentDate.isValid()) {
        return "N/A";
    }

    let format = isShort ? "ll" : "LL";

    if (showDay) {
        format = `${isShort ? "ddd" : "dddd"} ${format}`;
    }

    return momentDate.format(format);
};

export const toHumanTime = (date?: AcceptedDate): string => {
    if (!date) {
        return "N/A";
    }

    const momentDate = moment(date);

    if (!momentDate.isValid()) {
        return "N/A";
    }

    const locale = moment.locale();

    // in French only if minutes are 0 then dont even display them
    // ex: 15h00 => 15h
    // ex: 15h20 => 15h20
    if (locale === "fr") {
        if (momentDate.minutes() === 0) {
            return momentDate.format("H[h]");
        }
    }

    return momentDate.format("LT");
};

export const toHumanDateTime = (date?: AcceptedDate, isShort = false, showDay = false): string => {
    if (!date) {
        return "";
    }

    const momentDate = moment(date);

    // if time is exactly midnight, assume we don't want to show the time and show the date only instead
    if (momentDate.hours() === 0 && momentDate.minutes() === 0) {
        return translate("shared.helpers.toHumanDateTime.noTime", {
            date: toHumanDate(date, isShort, showDay),
        });
    }

    if (isShort) {
        return `${toHumanDate(date, isShort, showDay)} ${toHumanTime(momentDate)}`;
    }

    return translate("shared.helpers.toHumanDateTime.at", {
        date: toHumanDate(date, isShort, showDay),
        time: toHumanTime(momentDate),
    });
};

type EngagementTimeslotDateRange = {
    duration: number | null;
    end_datetime: string | null;
    start_datetime: string;
};

// converts a date to a formatted span string
export const toHumanDateSpan = (
    startDateTime?: AcceptedDate,
    endDateTime?: AcceptedDate,
    isShort = false,
    showDay = false
): string => {
    if (!startDateTime) {
        return "";
    }

    if (!endDateTime) {
        return translate("shared.helpers.toHumanDateSpan.openEnded", {
            date: toHumanDate(startDateTime, isShort, showDay),
        });
    }

    const startDateTimeInMoment = moment(startDateTime);
    const endDateTimeInMoment = moment(endDateTime);

    // same day
    if (startDateTimeInMoment.isSame(endDateTimeInMoment, "day")) {
        // same time
        if (startDateTimeInMoment.isSame(endDateTimeInMoment, "minute")) {
            return toHumanDateTime(startDateTime, isShort, showDay);
        }

        // different time
        return translate("shared.helpers.toHumanDateSpan.sameDay.timeRange", {
            date: toHumanDate(startDateTime, isShort, showDay),
            end_time: toHumanTime(endDateTimeInMoment),
            start_time: toHumanTime(startDateTimeInMoment),
        });
    }

    // different day
    return translate("shared.helpers.toHumanDateSpan.dateRange", {
        end_date: toHumanDate(endDateTime, isShort, showDay),
        start_date: toHumanDate(startDateTime, isShort, showDay),
    });
};

export const toDate = (date: string): Date => {
    return moment(date).toDate();
};

// converts a date to ISO format
export const toISODate = (date?: AcceptedDate): string => {
    return moment(date).format();
};

// converts a date to a formatted string
export const toFormatDate = (date?: AcceptedDate, format?: string): string => {
    if (!date || !format) {
        return "";
    }

    return moment(date).format(format);
};

export const getDay = (): string => {
    return toFormatDate(moment(), "dddd");
};

export const getLastTwelveMonthsISODateRange = (): string => {
    return `${moment().utc().subtract(12, "month").startOf("day").format()};${moment().utc().endOf("day").format()}`;
};

export const getSinceFirstJanuaryISODateRange = (): string => {
    return `${moment().utc().startOf("year").startOf("day").format()};${moment().utc().endOf("day").format()}`;
};

export const isDateBeforeNow = (date?: AcceptedDate): boolean => {
    return moment(date).isBefore(moment());
};

export const getEngagementTimeslotDateDisplay = (
    engagementTimeslot: EngagementTimeslotDateRange,
    isDuringWorktime = true
): string => {
    const { duration, end_datetime: endDateTime, start_datetime: startDateTime } = engagementTimeslot;
    let dateDisplayed = toHumanDateSpan(startDateTime, endDateTime, false, true);
    const startDateTimeInMoment = moment(startDateTime);
    const endDateTimeInMoment = endDateTime ? moment(endDateTime) : null;
    const isSameDay = startDateTimeInMoment.isSame(endDateTimeInMoment, "day");
    const isNotShowingTime = !endDateTime || startDateTimeInMoment.isSame(endDateTimeInMoment, "minute");

    // display duration if we are:
    // - not same day ("From 13th march to 6th june during 2 days")
    // - not showing time ("The 13th march during 0.2 day")
    if (duration) {
        if (!isSameDay || isNotShowingTime) {
            dateDisplayed = translate("shared.helpers.getEngagementTimeslotDateDisplay.dateRangeDuring", {
                date_range: dateDisplayed,
                duration: toHumanDuration(duration),
            });
        }
    }

    if (isDuringWorktime) {
        return dateDisplayed;
    }

    return translate("shared.helpers.getEngagementTimeslotDateDisplay.outsideWorktime", {
        date_display: dateDisplayed,
    });
};

export const getDaysFromDuration = (duration: number): number => {
    return duration / CREDIT_HOURS_PER_DAY;
};

export const toHumanDuration = (days: number): string => {
    if (days >= 1) {
        return vueI18nInstance.plural("shared.layout.days", Math.round(days));
    }

    for (let i = 1; i < 7; i++) {
        if (days <= i / 7) {
            return vueI18nInstance.plural("shared.layout.hours", i);
        }
    }

    return vueI18nInstance.plural("shared.layout.days", 1);
};

export const toHumanDurationMinutes = (value: number): string => {
    const duration = moment.duration(value, "minutes");
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours) {
        if (minutes) {
            return vueI18nInstance.translate("shared.helpers.toHumanDurationMinutes.hoursAndMinutes", {
                hours,
                minutes,
            });
        }

        return vueI18nInstance.translate("shared.helpers.toHumanDurationMinutes.hours", {
            hours,
        });
    }

    return vueI18nInstance.translate("shared.helpers.toHumanDurationMinutes.minutes", {
        minutes,
    });
};

export const daysFor = (value?: number | null): string => {
    if (typeof value !== "number") {
        return vueI18nInstance.plural("shared.helpers.daysFor.days", 0);
    }

    // Math.ceil transform -0.5 to 0
    const valueForTranslation = value < 0 && value > -1 ? -1 : Math.ceil(value);

    return vueI18nInstance.plural("shared.helpers.daysFor.days", valueForTranslation, {
        days: toNumber(value, { maximumFractionDigits: 1 }),
    });
};

// get the day count that should be deducted from an employee's credit when applying to these start/end dates
export const getCreditDuration = (startDatetime: string, endDatetime: string): number => {
    if (!startDatetime || !endDatetime) {
        return 0;
    }

    return Math.abs(roundNumber(moment(endDatetime).diff(startDatetime, "hours", true) / CREDIT_HOURS_PER_DAY));
};

export const getAdvancedCreditDuration = (startDateTime: string, endDateTime: string): number => {
    const duration = getCreditDuration(startDateTime, endDateTime);
    const days = Math.floor(duration);
    const remainsDay = duration - days;
    const tolerance = 0.001;

    if (remainsDay > 0.7 + tolerance) {
        return days + 1;
    }

    if (remainsDay > 0.1 + tolerance) {
        return days + 0.5;
    }

    return days;
};

export const getAbsenceDays = (startDateTime: string, endDateTime: string): number => {
    const startHours = moment(startDateTime).hours();
    const endHours = moment(endDateTime).hours();

    if (startHours < 11 && endHours >= 14) {
        return AbsenceDays.Day;
    }

    return AbsenceDays.Half;
};

// get string that display a mission possible dates
export const getMissionDates = (mission: MissionLight): string => {
    if (mission.availability_type === "MISSION_AVAILABILITY_TYPE_TEXT") {
        return truncate(mission.required_availability, 40);
    }

    if (mission.availability_type === "MISSION_AVAILABILITY_TYPE_TIMESLOTS" && mission.future_timeslots.length > 0) {
        const displayedTimeslots = mission.future_timeslots.map((t) => {
            return toHumanDateSpan(t.start_datetime, t.end_datetime);
        });

        return displayedTimeslots[0];
    }

    return translate("shared.layout.noDateAvailable");
};

// get a timeslot from start/end datetime: either exact match or the first future one
export const getMatchingTimeslot = (
    timeslots: Timeslot[],
    startDatetime: string | null,
    endDatetime: string | null
): Timeslot | undefined => {
    if (timeslots.length === 0) {
        return;
    }

    // try to match timeslot to current start/end date
    if (startDatetime && endDatetime) {
        const timeslot = timeslots.find((t) => {
            return (
                moment(t.start_datetime).isSame(startDatetime, "minute") &&
                moment(t.end_datetime).isSame(endDatetime, "minute")
            );
        });

        if (timeslot) {
            return timeslot;
        }
    }

    // otherwise get first future timeslot
    return first(
        sortBy(
            ["start_datetime", "end_datetime"],
            timeslots.filter((t) => {
                return moment(t.start_datetime).isAfter(moment()) && moment(t.end_datetime).isAfter(moment());
            })
        )
    );
};

export const isNumber = (val: any): boolean => {
    return typeof val === "number" && val === val;
};

/**
 * FILE/DOWNLOAD
 */

// return FA icon corresponding to extension of file of url
export const getFileIcon = (url: string | number): string => {
    if (typeof url === "number" || !url) {
        return "file";
    }

    const extension = getExtension(url);

    if (fileExtensions.archive.includes(extension)) {
        return "file-archive";
    }

    if (fileExtensions.csv.includes(extension)) {
        return "file-csv";
    }

    if (fileExtensions.image.includes(extension)) {
        return "file-image";
    }

    if (fileExtensions.pdf.includes(extension)) {
        return "file-pdf";
    }

    if (fileExtensions.powerpoint.includes(extension)) {
        return "file-powerpoint";
    }

    if (fileExtensions.spreadsheet.includes(extension)) {
        return "file-excel";
    }

    if (fileExtensions.text.includes(extension)) {
        return "file-word";
    }

    return "file";
};

// return file extension from url
export const getExtension = (url: string): string => {
    if (!url || !url.includes(".")) {
        return "";
    }

    return lowerCase(last(url.split("?")[0].split(".")) || "");
};

// input can be a url, a blob or a file
export const downloadFile = async (input: string | Blob, name: string): Promise<void> => {
    // input is a url
    if (isString(input)) {
        try {
            const response = await fetch(input);

            if (!response.ok) {
                notify(translate("shared.helpers.downloadFile.fail"), "error");

                return;
            }

            // Extract filename from header
            const filename = response.headers
                ?.get("content-disposition")
                ?.split(";")
                .find((n) => {
                    return n.includes("filename=");
                })
                ?.replace("filename=", "")
                .trim();
            const blob = await response.blob();

            return saveAs(blob, filename);
        } catch (error) {
            notify(translate("shared.helpers.downloadFile.fail"), "error");

            return;
        }
    }

    // if blob
    return saveAs(input, name);
};

export const fileExtensions = {
    archive: ["zip", "rar", "tar"],
    csv: ["csv"],
    image: ["png", "jpg", "jpeg", "bmp", "svg", "gif", "jfif", "tif", "webp", "jp2", "heic"],
    pdf: ["pdf"],
    powerpoint: ["ppt", "pptx"],
    spreadsheet: ["xls", "xlsx"],
    text: ["txt", "doc", "docx", "odt"],
};

// return true if passed url ends with image extension
export const isImageUrl = (value?: string): boolean => {
    if (!value) {
        return false;
    }

    if (Number.isInteger(value)) {
        return false;
    }

    const extension = getExtension(value);

    return fileExtensions.image.includes(extension);
};

export const extractNameFileFromUrl = (url: string): string => {
    if (!url) {
        return "";
    }

    const match = url.match(/(?:.+\/)(.+)/);

    return match && match[1] ? match[1] : "";
};

export const isTypeformUrl = (url?: string): boolean => {
    if (!url || !url.length || url === "") {
        return false;
    }

    return /^https?:\/\/.+typeform.com\/to\/[a-zA-Z0-9]+/.test(url);
};

export const extractTypeformId = (url: string): string => {
    const match = url.match(/\/to\/([a-zA-Z0-9]+)(?:#.*)?/);

    return match && match[1] ? match[1] : "";
};

/**
 * TEXT/NUMBER FORMATTING
 */

// truncates text to a maximum length and append "..."
export const truncate = (text: string | null = "", limit = 0): string => {
    if (!text) {
        return "";
    }

    if (!limit) {
        return text;
    }

    if (text.length <= limit) {
        return text;
    }

    if (limit > 3) {
        return `${text.substr(0, limit - 3)}...`;
    }

    return `${text.substr(0, limit)}...`;
};

// format number (1234 => "1 234")
export const toNumber = (number: number, options?: Intl.NumberFormatOptions): string => {
    if (isNaN(number)) {
        return "0";
    }

    return new Intl.NumberFormat(vueI18nInstance.i18n?.locale || "en-US", options).format(number);
};

// remove useless decimal zeros
// ex: roundNumber(12.50) => 12.5
export const roundNumber = (num: number): number => {
    return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
};

export const capitalizeFirstLetter = ([first, ...rest]: string): string => {
    return [first.toUpperCase(), ...rest].join("");
};

export const limitDisplayedList = (
    list: any[] | string,
    threshold = 3,
    translationKey = "shared.helpers.limitDisplayedList.list"
): string => {
    if (!list || list.length === 0) {
        return "";
    }

    if (typeof list === "string") {
        list = list.split(", ");
    }

    if (list.length <= threshold) {
        return list.join(", ");
    }

    const displayed = list.slice(0, threshold).join(", ");
    const remaining = list.length - threshold;

    return vueI18nInstance.plural(translationKey, remaining, {
        displayed,
        remaining,
    });
};

export const slugify = flow([toLower, deburr, kebabCase]);

/**
 * LOCALISATION/LANG/COUNTRY
 */

// returns city and country from Google address format
// instead of full street address
export const toGoogleAddress = (address?: string | null): string => {
    if (!address) {
        return "";
    }

    const regex = /[0-9]{5} (.*)$/;

    if (regex.test(address)) {
        const parts = regex.exec(address);

        if (!parts || parts.length < 2) {
            return "";
        }

        return parts[1];
    }

    return address;
};

export const toCity = (address?: string): string => {
    if (!address) {
        return "";
    }

    // catch city after postal code
    // - "12 rue du test, 75010 Paris, France" => "Paris"
    // - "12 rue du test, 75010, Paris, France" => "Paris"
    const regex = /\d{2} ?\d{3},? ?([^,\n]+)(?=,? France$|$)/;

    if (regex.test(address)) {
        const parts = regex.exec(address);

        if (!parts || parts.length < 2) {
            return "";
        }

        return parts[1].trim();
    }

    // otherwise return the second to last part of the address:
    // - "12 rue du test, Paris, France" => "Paris"
    // - "Paris, France" => "Paris"
    // - "Paris" => "Paris"
    const splitAddress = address.split(",");
    const index = splitAddress.length - 2 >= 0 ? splitAddress.length - 2 : 0;

    return splitAddress[index].trim();
};

export const getCurrentLocale = (lang?: string): string => {
    if (!!lang && langExistsInApp(lang)) {
        return lang;
    }

    if (langExistsInApp(navigator.language)) {
        return navigator.language.substr(0, 2);
    }

    // return en as default
    return "en";
};

export const langExistsInApp = (langOrLocale: string): boolean => {
    const lang = langOrLocale.length === 2 ? langOrLocale : langOrLocale.substr(0, 2);

    return languages.some((language) => {
        return language.value === lang;
    });
};

// transforms a country code (ISO 3166-1 alpha-2) into corresponding flag emoji
export const countryCodeToFlag = (code: string): string => {
    return String.fromCodePoint(
        ...toUpper(code)
            .split("")
            .map((c) => {
                return 127397 + c.charCodeAt(0);
            })
    );
};

export type Country = {
    label: string;
    value: string;
};

// get list of all countries around the world
export const getWorldCountries = (userLang?: string, withFlags = false): Country[] => {
    const allCountries = countries.getNames(userLang ?? "en", { select: "official" });
    const mappedCountries = Object.entries(allCountries).map(([code, name]) => {
        return {
            label: withFlags ? `${countryCodeToFlag(code)} ${name}` : name,
            value: code.toLowerCase(),
        };
    });

    return sortBy(["label"], mappedCountries);
};

/**
 * OBJECT/ARRAY MANIPULATION
 */

// check if an object of string properties has changed
export const hasChanged = (source: object, updated: object): boolean => {
    // compare only truthy keys (don't consider that a null becoming an empty string is a change)
    return !isEqual(pickBy(Boolean, source), pickBy(Boolean, updated));
};

// turn string of text lines separated by a new line to array of strings
// "a@g.com b@g.com" => ["a@g.com", "b@g.com"]
export const splitOnNewLine = (text: string): string[] => {
    return (
        text
            // replace spaces and new lines by commas
            .replace(/\s+/gm, ",")
            // replace ";" by commas (coming from Excel copy)
            .replace(/;+/gm, ",")
            .split(",")
            .map((value) => {
                return value.trim().toLowerCase();
            })
            .filter(Boolean)
    );
};

// returns true if all value of the filters object are falsy or empty
export const isEmptyDeep = (value?: any): boolean => {
    if (!value) {
        return true;
    }

    if (isArray(value)) {
        return value.every(isEmptyDeep);
    }

    if (isObject(value)) {
        return Object.values(value).every(isEmptyDeep);
    }

    return false;
};

/**
 * TABLES
 */

export type ValueLabel = {
    label: string;
    value: string;
};

/**
 * FILTERS
 */

/**
 * filter Thematic
 */
export type ThematicOptions = ReturnType<typeof getThematics>["thematics"];

const filterThematicsByCompany = (thematics: ThematicOptions, company: Company): ThematicOptions => {
    if (!company) {
        return thematics;
    }

    return thematics.filter((thematic) => {
        return company.thematics.includes(thematic.value);
    });
};

export const getFilteredThematicsByCompany = (company: Company): ThematicOptions => {
    const { thematics } = getThematics();

    return filterThematicsByCompany(thematics, company);
};

/**
 * filter Missions Types
 */
export type MissionTypeOptions = ReturnType<typeof getMissionTypes>["missionTypes"];

export const filterMissionTypesByCompany = (
    missionTypes: MissionTypeOptions,
    company: Company | null
): MissionTypeOptions => {
    if (!company) {
        return missionTypes;
    }

    return missionTypes.filter((missionType) => {
        return company.mission_types.includes(missionType.value);
    });
};

export const getFilteredMissionTypesByCompany = (company: Company | null): MissionTypeOptions => {
    const { missionTypes } = getMissionTypes();

    return filterMissionTypesByCompany(missionTypes, company);
};

/**
 * filter Skills
 */
export type SkillOptions = ReturnType<typeof getSkills>["skills"];

export const filterSkillsByCompany = (skills: SkillOptions, company: Company): SkillOptions => {
    if (!company) {
        return skills;
    }

    return skills.filter((thematic) => {
        return company.skills.includes(thematic.value);
    });
};

export const getFilteredSkillsByCompany = (company: Company): SkillOptions => {
    const { skills } = getSkills();

    return filterSkillsByCompany(skills, company);
};

/**
 * random filters
 */

export const getFilteredCountries = (selectedCountries: string[], userLang?: string, withFlags = false): Country[] => {
    return getWorldCountries(userLang, withFlags).filter((country) => {
        return selectedCountries.includes(country.value);
    });
};

/**
 * VALIDATION
 */

export const startsWithVowel = (text: string): boolean => {
    return /^[aeiouy]/i.test(deburr(text));
};

export const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

/**
 * RESPONSIVE
 */

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

const getBreakpointWidth = (breakpoint: Breakpoint): number => {
    const width = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--breakpoint-${breakpoint}`)
        .trim();

    if (width) {
        return parseInt(width);
    }

    throw new Error(`Breakpoint ${breakpoint} is not defined`);
};

export const getCurrentWindowBreakpoint = (): string => {
    const windowWidth = window.innerWidth;

    if (windowWidth < getBreakpointWidth("sm")) {
        return "xs";
    }

    if (windowWidth < getBreakpointWidth("md")) {
        return "sm";
    }

    if (windowWidth < getBreakpointWidth("lg")) {
        return "md";
    }

    if (windowWidth < getBreakpointWidth("xl")) {
        return "lg";
    }

    return "xl";
};

/**
 * IMAGE
 */

export const extractColorFromUrl = async (url: string, resize = true): Promise<string | null> => {
    // resize on the fly to ensure we're not trying to analyze a 25MB image in browser. This resize only works on images
    // that are hosted by us of course
    const urlToAnalyse = resize ? `${url}?size=sm` : url;
    const colorThief = new ColorThief();
    const img = new Image();

    // wrap the getting + analyze of the image in a try catch to handle any issue without breaking
    try {
        // call the extract color function on the img element when it's loaded
        const colorRgb: number[] = await new Promise((resolve) => {
            img.addEventListener("load", () => {
                resolve(colorThief.getColor(img));
            });

            img.crossOrigin = "Anonymous";
            img.src = urlToAnalyse;
        });
        const colorHex = chroma(colorRgb).hex();

        return pastelizeColor(colorHex);
    } catch (error) {
        Sentry.captureException(error);

        return null;
    }
};

export const getImageDimensions = async (
    file: File
): Promise<{
    height: number;
    width: number;
} | null> => {
    const isImage = isImageUrl(file.name);

    if (!isImage) {
        return null;
    }

    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            // file is loaded
            const img = new Image();

            img.onload = () => {
                // image is loaded; sizes are available
                return resolve({
                    height: img.height,
                    width: img.width,
                });
            };

            img.onerror = () => {
                return reject("Error when trying to read image file");
            };

            // is the data URL because called with readAsDataURL
            if (!fileReader.result) {
                return reject("Error when trying to read image file, result is null");
            }

            if (typeof fileReader.result === "string") {
                img.src = fileReader.result;
            } else {
                // The goal is to convert ArrayBuffer to String, as fileReader.result can be both.
                // But there is no easy way to do it in JavaScript, that's why we have to cast
                // ArrayBuffer to Array of UnsignedInt Array then in String
                img.src = String.fromCharCode.apply(null, Array.from(new Uint16Array(fileReader.result)));
            }
        };

        fileReader.readAsDataURL(file);
    });
};

export const pastelizeColor = (colorHex: string): string => {
    // convert to hsl array
    const colorHsl = chroma.hex(colorHex).hsl();

    return (
        chroma
            // set saturation & lightness to fixed values - this gives the color a "pastel" vibe
            .hsl(colorHsl[0], 0.6, 0.9)
            // convert to hex value
            .hex()
    );
};

export const getUserProfileCompletionRate = (user: User, requiredFields: string[]): number => {
    const filledProps = requiredFields.filter((field) => {
        const value = get(field, user);

        if (isArray(value)) {
            return value.length > 0;
        }

        return !!value;
    });

    // compare filled props vs required field lengths and convert it to percentage
    // round is to avoid "14,247% completion rate"
    return Math.round((filledProps.length * 100) / requiredFields.length);
};

// usage: await wait(1000) to wait 1 seconde
export const wait = (ms: number): Promise<unknown> => {
    return new Promise((resolve) => {
        return setTimeout(resolve, ms);
    });
};
