import { Company } from "@store/resources";
import { CompanyFeature, CompanyFeatures } from "@store/resources/Company";

const areCompanyFeaturesActivated = (
    companyFeaturesConfiguration: CompanyFeatures,
    companyFeaturesActivated: CompanyFeature[]
): boolean => {
    if (!companyFeaturesConfiguration) {
        return false;
    }

    return companyFeaturesActivated.every((featureName) => {
        return companyFeaturesConfiguration[featureName];
    });
};

const isCompanyFeatureVendrediAllowed = (
    companyFeature: CompanyFeature | CompanyFeature[],
    company: Company
): boolean => {
    return areCompanyFeaturesActivated(
        company.vendredi_allowed_features,
        Array.isArray(companyFeature) ? companyFeature : [companyFeature]
    );
};

const isCompanyFeatureCompanyEnabled = (
    companyFeature: CompanyFeature | CompanyFeature[],
    company: Company
): boolean => {
    return areCompanyFeaturesActivated(
        company.company_enabled_features,
        Array.isArray(companyFeature) ? companyFeature : [companyFeature]
    );
};

const getters = () => {
    return {
        areCompanyFeaturesActivated,
        isCompanyFeatureCompanyEnabled,
        isCompanyFeatureVendrediAllowed,
    };
};

export default getters;
