import { isObject } from "lodash/fp";
import _Vue from "vue";

import { Id } from "@store/resources/Api";

export type PanelName =
    | "challenge"
    | "catalog-highlight-details"
    | "create-branch"
    | "create-engagements"
    | "create-timeslots"
    | "edit-branch"
    | "engagement"
    | "engagement-follow-up"
    | "engagement-timeslots"
    | "engagement-timeslot-management"
    | "impact-content"
    | "import-volunteers"
    | "level"
    | "quest-finished"
    | "level-achievements"
    | "member-upsert-panel"
    | "mission-preview"
    | "participations"
    | "ranking-achievers"
    | "ranking-associations"
    | "ranking-entity-achievements"
    | "ranking-entity-engagements"
    | "ranking-mission-engaged-employees"
    | "ranking-missions"
    | "suggestion"
    | "tag-upsert"
    | "user-achievements"
    | "volunteer-contact";

export function panel(this: _Vue) {
    return {
        close: (name: PanelName) => {
            this.$root.$emit("panel:close", name);
        },
        open: (name: PanelName, idOrData: Id | object) => {
            const idOrDataIsData = isObject(idOrData);
            const id = idOrDataIsData ? null : idOrData;
            const data = idOrDataIsData ? idOrData : null;

            this.$root.$emit("panel:open", name, id, data);
        },
    };
}

export default {
    install(Vue: typeof _Vue) {
        Vue.prototype.$panel = panel;
    },
};
