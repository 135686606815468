<template>
    <div class="ui-responsive-image" :style="style" />
</template>

<style lang="scss" scoped>
.ui-responsive-image {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    background-color: #f5f8f8;
}
</style>

<script lang="ts">
// adaptative image that will always look good no matter its width and height

import Vue from "vue";

export default Vue.extend({
    props: {
        height: Number,
        url: {
            required: true,
            type: String,
        },
        width: Number,
    },
    computed: {
        style(): Partial<CSSStyleDeclaration> {
            const style: Partial<CSSStyleDeclaration> = {
                backgroundImage: `url('${this.url}')`,
            };

            if (this.height) {
                style.height = `${this.height}px`;
            }

            if (this.width) {
                style.width = `${this.width}px`;
            }

            return style;
        },
    },
});
</script>
