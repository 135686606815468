<template>
    <Multiselect
        ref="multiselect"
        class="multiselect"
        :class="{
            'is-invalid': state === false,
        }"
        :close-on-select="!multi"
        :deselect-group-label="$t('shared.components.multiSelect.deselectGroup')"
        group-label="label"
        :group-select="grouped"
        :group-values="grouped ? 'options' : null"
        hide-selected
        label="label"
        :max="max"
        :multiple="multi"
        :option-height="40"
        :options="options"
        :placeholder="inputPlaceholder"
        :select-group-label="$t('shared.components.multiSelect.selectGroup')"
        select-label=""
        :show-no-options="showNoOptions"
        track-by="value"
        :value="selectedOptions"
        @input="handleInput"
    >
        <template v-if="enableSelectAllOptions" #beforeList>
            <span class="border-bottom font-weight-bold multiselect__option" @click="toggleSelectAll">
                {{ hasSelectedAllOptions ? $t("shared.layout.unselectAll") : $t("shared.layout.selectAll") }}
            </span>
        </template>

        <template #noResult>
            <span class="text-muted">{{ $t("shared.components.multiSelect.noResult") }}</span>
        </template>

        <template #afterList>
            <span v-if="hasSelectedAllOptions && !inputSearch" class="multiselect__option text-muted">
                {{ emptyStateText?.length ? emptyStateText : $t("shared.components.multiSelect.noOption") }}
            </span>
        </template>

        <!-- inherit slots -->
        <template v-if="$scopedSlots.option" #option="scope">
            <slot name="option" v-bind="scope" />
        </template>

        <template v-if="!multi" #singleLabel="{ option }">
            <span v-if="clearable">
                <span>{{ option.label }}</span>

                <i
                    class="multiselect__tag-icon"
                    tabindex="1"
                    @keypress.enter.prevent="clearValue"
                    @mousedown.prevent="clearValue"
                />
            </span>
            <template v-else>{{ option.label }}</template>
        </template>

        <template #maxElements>
            {{
                $t("shared.components.multiSelect.maxElements", {
                    max,
                })
            }}
        </template>

        <!-- overriding https://github.com/shentao/vue-multiselect/blob/master/src/Multiselect.vue -->
        <template v-if="$scopedSlots.tag" #tag="{ option, remove, index }">
            <span :key="index" class="multiselect__tag">
                <slot name="tag" v-bind="{ option }" />

                <i
                    class="multiselect__tag-icon"
                    tabindex="1"
                    @keypress.enter.prevent="remove(option)"
                    @mousedown.prevent="remove(option)"
                />
            </span>
        </template>
    </Multiselect>
</template>

<style lang="scss">
@import "variables";

.multiselect .multiselect__option--selected.multiselect__option--highlight {
    background: #f3f3f3;
    color: #35495e;

    &::after {
        background: #f3f3f3;
        color: #c2c2c2;
    }
}
</style>

<script lang="ts">
import Vue, { PropType } from "vue";
import Multiselect from "vue-multiselect";

export type MultiSelectOption<T = string> = {
    value: T;
    label: string;
};

export type MultiSelectOptionsGroup = {
    label: string;
    options: MultiSelectOption[];
};

type Options = MultiSelectOption[] | MultiSelectOptionsGroup[];

export default Vue.extend({
    components: { Multiselect },
    props: {
        clearable: {
            default: false,
            type: Boolean,
        },
        emptyStateText: String,
        enableSelectAllOptions: Boolean,
        // if true, options should be grouped like in MultiSelectOptionsGroup
        grouped: Boolean,
        // number of allowed selected options
        max: Number,
        multi: {
            default: true,
            type: Boolean,
        },
        showNoOptions: Boolean,
        options: {
            required: true,
            type: Array as PropType<Options>,
        },
        placeholder: String,
        state: {
            default: null,
            type: Boolean,
        },
        value: [String, Number, Array] as PropType<string | number | number[] | string[] | null>,
    },
    data() {
        return {
            hasMounted: false,
        };
    },
    mounted() {
        this.hasMounted = true;
    },
    computed: {
        availableOptions(): MultiSelectOption[] {
            // when grouped, consider only the real final options nested inside passed grouped options
            if (this.grouped) {
                return this.options.flatMap((option) => {
                    return option.options;
                });
            }

            // otherwise options are shallow
            return this.options;
        },
        hasSelectedAllOptions(): boolean {
            if (this.multi && Array.isArray(this.value)) {
                return this.availableOptions.length <= this.value.length;
            }

            return false;
        },
        inputPlaceholder(): string {
            if (!this.placeholder) {
                return this.$t("shared.components.multiSelect.search");
            }

            if (this.multi && Array.isArray(this.value) && this.value.length === 0) {
                return this.placeholder;
            }

            return this.$t("shared.components.multiSelect.search");
        },
        // return search term from multiselect component (= text in search input)
        inputSearch(): string | null {
            // multiselect may not be mounted immediately when this is called
            // wait for entire component to mount before accessing it
            if (!this.hasMounted) {
                return null;
            }

            return this.$refs.multiselect.search;
        },
        selectedOptions(): MultiSelectOption[] {
            return this.availableOptions.filter((option) => {
                if (this.multi && Array.isArray(this.value)) {
                    return this.value.includes(option.value);
                }

                return this.value === option.value;
            });
        },
    },
    methods: {
        clearValue() {
            this.$emit("input", null);
        },
        handleInput(optionOrOptions: MultiSelectOption | MultiSelectOption[] | null) {
            if (optionOrOptions === null) {
                return;
            }

            if (Array.isArray(optionOrOptions)) {
                const values = optionOrOptions.map((option) => {
                    return option.value;
                });

                this.$emit("input", values);
            } else {
                this.$emit("input", optionOrOptions.value);
            }
        },
        toggleSelectAll(): void {
            if (!this.enableSelectAllOptions) {
                return;
            }

            const allValues = this.availableOptions.map((option) => {
                return option.value;
            });
            const allSelected = allValues.every((value) => {
                return this.value.includes(value);
            });

            this.$emit("input", allSelected ? [] : allValues);
        },
    },
});
</script>
