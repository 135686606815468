<template>
    <modal :open="isOpen" @close="handleClose">
        <template #modal-header="{ close }">
            <div class="modal-title">
                <h5 class="mb-0">{{ $t("shared.components.associations.supervisorListModal.header.title") }}</h5>
                {{ $t("shared.components.associations.supervisorListModal.header.subtitle") }}
            </div>

            <b-button-close @click="close" />
        </template>

        <template #default>
            <p class="font-bold">{{ $t("shared.components.associations.supervisorListModal.main.listDescription") }}</p>

            <ul>
                <li v-for="supervisor in supervisors" :key="supervisor.id">
                    {{ supervisor.full_name }} ({{ supervisor.email }})
                </li>
            </ul>
        </template>

        <template #modal-footer="{ close }">
            <b-button variant="primary" @click="close">{{
                $t("shared.components.associations.supervisorListModal.footer.leave")
            }}</b-button>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";

import { AssociationSupervisor } from "@store/resources/Association";

export default Vue.extend({
    props: {
        isOpen: {
            required: true,
            type: Boolean,
        },
        supervisors: {
            required: true,
            type: Array as PropType<Partial<AssociationSupervisor>[]>,
        },
    },
    methods: {
        handleClose(): void {
            this.$emit("close");
        },
    },
});
</script>
