import Vue, { DirectiveBinding } from "vue";

/*
 * Ensure the autoheight behaviour, takes an offset in pixels to adjust for borders
 */

const autoheight = (el: HTMLElement, binding: DirectiveBinding<number>) => {
    el.style.height = "";
    // + 2 for the borders
    el.style.height = `${Math.max(80, el.scrollHeight) + (isFinite(binding.value) ? binding.value : 0)}px`;
};

Vue.directive("autoheight", {
    bind(el: HTMLElement, binding: DirectiveBinding<number>) {
        el.addEventListener("input", () => {
            return autoheight(el, binding);
        });
    },
    inserted(el: HTMLElement, binding: DirectiveBinding<number>) {
        autoheight(el, binding);
    },
    update(el: HTMLElement, binding: DirectiveBinding<number>) {
        autoheight(el, binding);
    },
});
