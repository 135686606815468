var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({ title: _vm.tooltip, placement: _vm.placement }),expression:"{ title: tooltip, placement: placement }"}],staticClass:"fa-fw flex-shrink-0",class:[
        `fa-${_vm.icon}`,
        _vm.variant,
        {
            'fa-spin': _vm.spin,
            [`fa-${_vm.size}`]: _vm.size,
        },
    ],on:{"click":_vm.handleClick}})
}
var staticRenderFns = []

export { render, staticRenderFns }