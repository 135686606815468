var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"heading",class:[
        _vm.alignTop ? 'align-items-start' : 'align-items-center',
        {
            'mt-5': _vm.marginTop,
            'justify-content-center': _vm.centerAlign,
        },
    ]},[_c('div',{staticClass:"d-flex flex-column",class:{ 'align-items-end': _vm.rightAlign, 'flex-grow-1': _vm.fullWidth }},[(_vm.backlink)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',_vm._b({staticClass:"mb-4 mr-auto"},'b-button',_vm.backlinkButton,false),[_c('icon',{staticClass:"mr-2",attrs:{"icon":"chevron-left"}}),_vm._v("\n                "+_vm._s(_vm.backlink.content || _vm.$t("shared.layout.back"))+"\n            ")],1),_vm._v(" "),_vm._t("actions")],2):(_vm.subtitle)?_c('h6',{staticClass:"uppercase-subtitle"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"align-content-between d-flex"},[_c('div',[(_vm.level == 1)?_c('h1',{staticClass:"m-0"},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                    "),(_vm.optional)?_c('small',{staticClass:"font-weight-normal text-muted"},[_vm._v("\n                        • "+_vm._s(_vm.$t("shared.layout.optional"))+"\n                    ")]):_vm._e()]):(_vm.level == 2)?_c('h2',{staticClass:"m-0"},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                    "),(_vm.optional)?_c('small',{staticClass:"font-weight-normal text-muted"},[_vm._v("\n                        • "+_vm._s(_vm.$t("shared.layout.optional"))+"\n                    ")]):_vm._e()]):_vm._e(),_vm._v(" "),_vm._t("badge")],2),_vm._v(" "),_vm._t("left")],2),_vm._v(" "),(_vm.description)?_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.strip(_vm.description)),expression:"strip(description)"}],staticClass:"text-muted"}):_vm._e()]),_vm._v(" "),(_vm.button)?_c('div',[(_vm.button.action)?_c('b-button',{staticClass:"ml-2",class:_vm.button.className,attrs:{"data-cy":_vm.button.cypressTag,"disabled":_vm.button.disabled || false,"variant":_vm.button.variant || 'primary'},on:{"click":_vm.button.action}},[(_vm.button.icon)?_c('icon',{class:{
                    'mr-2': _vm.button.content,
                },attrs:{"icon":_vm.button.icon}}):_vm._e(),_vm._v("\n            "+_vm._s(_vm.button.content)+"\n        ")],1):_vm._e(),_vm._v(" "),(_vm.button.href)?_c('b-button',{staticClass:"ml-2",class:_vm.button.className,attrs:{"disabled":_vm.button.disabled || false,"href":_vm.button.href,"target":_vm.button.blank ? '_blank' : '_self',"variant":_vm.button.variant || 'primary'}},[(_vm.button.icon)?_c('icon',{class:{
                    'mr-2': _vm.button.content,
                },attrs:{"icon":_vm.button.icon}}):_vm._e(),_vm._v("\n            "+_vm._s(_vm.button.content)+"\n        ")],1):(_vm.button.to)?_c('b-button',{staticClass:"ml-2",class:_vm.button.className,attrs:{"to":_vm.button.to,"variant":_vm.button.variant || 'primary'}},[(_vm.button.icon)?_c('icon',{staticClass:"mr-2",attrs:{"icon":_vm.button.icon}}):_vm._e(),_vm._v("\n            "+_vm._s(_vm.button.content)+"\n        ")],1):_vm._e()],1):(_vm.hasSlot)?[_c('div',{staticClass:"heading-slot ml-3"},[_vm._t("default")],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }