<template>
    <div class="forms-field-error invalid-feedback" data-cy="form_field_error">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.forms-field-error {
    display: block;
    margin-top: 4px;
}
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>
