import { keyBy, sortBy } from "lodash/fp";

const languages = sortBy(["label"], <const>[
    {
        label: "العربية",
        value: "ar",
    },
    {
        label: "Deutsch",
        value: "de",
    },
    {
        label: "English",
        value: "en",
    },
    {
        label: "Español",
        value: "es",
    },
    {
        label: "Français",
        value: "fr",
    },
    {
        label: "Italiano",
        value: "it",
    },
    {
        label: "Nederlands",
        value: "nl",
    },
    {
        label: "Português",
        value: "pt",
    },
    {
        label: "Русский",
        value: "ru",
    },
    {
        label: "中文(简体)",
        value: "zh",
    },
]);

export const languagesForEmail = sortBy(["label"], <const>[
    {
        label: "Deutsch",
        value: "de",
    },
    {
        label: "English",
        value: "en",
    },
    {
        label: "Español",
        value: "es",
    },
    {
        label: "Français",
        value: "fr",
    },
]);

export type Language = (typeof languages)[number]["value"];

export const languageByValue = keyBy("value", languages);

export default languages;
