import { deburr, keyBy, sortBy } from "lodash/fp";

import { translate } from "@i18n/instance";

const getMissionTypes = () => {
    return <const>[
        {
            description: translate("shared.utils.missionTypes.help.description"),
            determiner: translate("shared.utils.missionTypes.help.determiner"),
            help: translate("shared.utils.missionTypes.help.help"),
            icon: "hands-helping",
            image: "https://static.vendredi.cc/assets/illustrations/food.png?size=sm",
            label: translate("shared.utils.missionTypes.help.label"),
            value: "MISSION_TYPE_HELP",
        },
        {
            description: translate("shared.utils.missionTypes.skill.description"),
            determiner: translate("shared.utils.missionTypes.skill.determiner"),
            help: translate("shared.utils.missionTypes.skill.help"),
            icon: "user-tie",
            image: "https://static.vendredi.cc/assets/illustrations/company.png?size=sm",
            label: translate("shared.utils.missionTypes.skill.label"),
            value: "MISSION_TYPE_SKILL",
        },
        {
            description: translate("shared.utils.missionTypes.mentor.description"),
            determiner: translate("shared.utils.missionTypes.mentor.determiner"),
            help: translate("shared.utils.missionTypes.mentor.help"),
            icon: "comments",
            image: "https://static.vendredi.cc/assets/illustrations/mentor.png?size=sm",
            label: translate("shared.utils.missionTypes.mentor.label"),
            value: "MISSION_TYPE_MENTOR",
        },
    ];
};

export type MissionType = ReturnType<typeof getMissionTypes>[number]["value"];

const getters = () => {
    const missionTypes = getMissionTypes();

    return {
        missionTypeByValue: keyBy("value", missionTypes),
        missionTypes: sortBy((missionType) => {
            return deburr(missionType.label);
        }, missionTypes),
        missionTypesNativeSort: missionTypes,
    };
};

export default getters;
