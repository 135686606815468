<template>
    <div class="layout">
        <div class="content mt-5 p-3 p-md-0">
            <slot />
        </div>

        <Footer type="public" />
    </div>
</template>

<style lang="scss" scoped>
@import "mixins";
@import "variables";

.layout {
    background: $color-white;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.content {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    @include media-below("sm") {
        width: 100%;
    }
}
</style>

<script lang="ts">
import Vue from "vue";

import Footer from "@components/layout/Footer.vue";

export default Vue.extend({
    components: { Footer },
});
</script>
