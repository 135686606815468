<template>
    <img alt="Logo Vendredi" class="shared-login-logo" :src="logo" />
</template>

<style lang="scss" scoped>
.shared-login-logo {
    width: 90px;
}
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        logo: {
            default: "https://static.vendredi.cc/assets/logos/vendredi-logo.png?size=sm",
            type: String,
        },
    },
});
</script>
