import Vue from "vue";

import { Role } from "@utils/roles";

const RolePlugin = {
    install: function (vue: typeof Vue): void {
        vue.prototype.$role = Role;
    },
};

export default RolePlugin;
