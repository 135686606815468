<template>
    <div class="feature-message" :class="[`feature-message-${variant}`]">
        <div class="d-flex flex-column justify-content-center">
            <h2 class="title">
                {{ title }}
            </h2>

            <div class="message">
                <slot />
            </div>

            <div class="buttons">
                <slot name="buttons" />
            </div>
        </div>

        <div class="image-container">
            <img v-if="image" class="image" :src="image" />

            <slot v-else-if="$slots['image']" name="image" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "mixins";
@import "variables";

.feature-message {
    padding: 16px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .message {
        margin-right: 30px;
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        color: $text-muted;

        a {
            color: inherit;
        }
    }

    &-primary {
        background-color: $color-primary-basic;
        border: 1px solid $color-primary-basic;

        .title {
            color: $color-white;
        }

        .message {
            color: $color-white;
        }
    }

    &-white {
        background-color: $color-white;
        border: 1px solid $color-border;

        .title {
            color: $color-black;
        }

        .message {
            color: $text-muted;
        }
    }

    &-info {
        background-color: $color-info-light;
        border: 1px solid $color-border;

        .title {
            color: $color-black;
        }

        .message {
            color: $text-muted;
        }
    }

    .title {
        display: flex;
        flex-direction: row;
    }

    .image-container {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        @include media-below("sm") {
            display: none;
        }

        .image {
            max-height: 128px;
        }
    }

    .buttons {
        margin-top: 16px;
        gap: 8px;
        display: flex;
        flex-direction: row;
    }
}
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        image: String,
        title: {
            required: true,
            type: String,
        },
        variant: {
            required: true,
            type: String,
        },
    },
});
</script>
