import { merge } from "lodash/fp";
import { LocaleMessages } from "vue-i18n/types";

export const getTranslations = async (app: string, locale: string): Promise<LocaleMessages> => {
    // Webpack optimization: dynamically load language translations files for asked app and asked locale
    const { default: sharedTranslations } = await import(/* webpackPreload: true */ `./shared/${locale}.json`);

    if (app === "admin" || app === "shared") {
        return { [locale]: sharedTranslations };
    }

    const { default: appTranslations } = await import(/* webpackPreload: true */ `@apps/${app}/i18n/${locale}.json`);

    return { [locale]: merge(appTranslations, sharedTranslations) };
};
