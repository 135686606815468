<template>
    <!-- Only displayed on large screens when no burger menu-->
    <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown class="navbar-dropdown" right>
            <template #button-content>
                <template v-if="isUserImpersonated">
                    <span class="alert-danger">
                        {{ $t("shared.components.accountInfo.impersonatedUser", { user: user.full_name }) }}
                    </span>
                </template>

                <template v-else>{{ user.full_name }}</template>
            </template>

            <b-dropdown-item :href="actions.users.edit">
                <icon class="mr-2" icon="user-circle" />
                {{ $t("shared.components.accountInfo.account") }}
            </b-dropdown-item>

            <b-dropdown-item v-if="user.has_multiple_organisations" :href="actions.users.show_switch_organisation">
                <icon class="mr-2" icon="exchange-alt" />
                {{ $t("shared.components.accountInfo.switchOrganization") }}
            </b-dropdown-item>

            <b-dropdown-item v-if="isUserImpersonated" :href="actions.impersonate_leave">
                <icon class="mr-2" icon="user-slash" />
                {{ $t("shared.components.accountInfo.leaveImpersonate") }}
            </b-dropdown-item>

            <b-dropdown-item :href="logoutUrl" link-class="text-danger">
                <icon class="mr-2" icon="sign-out-alt" />
                {{ $t("shared.components.accountInfo.logout") }}
            </b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar-nav>
</template>

<script lang="ts">
import Vue from "vue";

import ExternalRouter from "@utils/externalRouter";

export default Vue.extend({
    name: "AccountInfo",
    props: {
        actions: {
            required: true,
            type: Object,
        },
        activeRoute: {
            default: "",
            type: String,
        },
        employee: {
            required: true,
            type: Object,
        },
        isUserImpersonated: {
            required: true,
            type: Boolean,
        },
        user: {
            required: true,
            type: Object,
        },
    },
    computed: {
        logoutUrl(): string {
            return ExternalRouter.url("logout");
        },
    },
});
</script>
