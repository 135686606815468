import "core-js/stable";
import "regenerator-runtime/runtime";
import "intersection-observer";

import "./bootstrap";
import "./mixins/AutoheightDirective";

import EmployeeModal from "@apps/employee/components/EmployeeModal.vue";
import Join from "@apps/public/pages/association/Join.vue";
import Callout from "@components/messages/Callout.vue";
import Feature from "@components/messages/Feature.vue";
import AccountInfo from "@components/shared/AccountInfo.vue";
import CardTabs from "@components/shared/CardTabs.vue";
import Flash from "@components/shared/Flash.vue";
import Lightbox from "@components/shared/Lightbox.vue";
import Logo from "@components/shared/Logo.vue";
import Modal from "@components/shared/Modal.vue";
import Panel from "@components/shared/Panel.vue";
import PanelArc from "@components/shared/PanelArc.vue";
import PanelBlock from "@components/shared/PanelBlock.vue";
import PanelTab from "@components/shared/PanelTab.vue";
import Heading from "@components/typography/Heading.vue";
import Icon from "@components/ui/Icon.vue";
import ResponsiveImage from "@components/ui/ResponsiveImage.vue";
import VueTagsInput from "@legacy-libs/vue-tags-input/vue-tags-input.vue";

const { Vue } = window;

/**
 * Typography
 */
Vue.component("Heading", Heading);

/**
 * Other
 */
Vue.component("AccountInfo", AccountInfo);

Vue.component("Callout", Callout);

Vue.component("EmployeeModal", EmployeeModal);

Vue.component("Feature", Feature);

Vue.component("Flash", Flash);

Vue.component("Icon", Icon);

Vue.component("Logo", Logo);

Vue.component("Lightbox", Lightbox);

Vue.component("Modal", Modal);

Vue.component("Panel", Panel);

Vue.component("PanelArc", PanelArc);

Vue.component("PanelBlock", PanelBlock);

Vue.component("PanelTab", PanelTab);

Vue.component("RImg", ResponsiveImage);

Vue.component("TabBar", CardTabs);

/**
 * ************************************************************************************************************
 * Librairies
 * ************************************************************************************************************
 */

Vue.component("VueTagsInput", VueTagsInput);

/**
 * *************************************************************************************************************
 * Pages
 **************************************************************************************************************
 */

/**
 * Association-app
 */
Vue.component("AssociationJoin", Join);
