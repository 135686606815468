import { castArray, mapValues } from "lodash/fp";
import { Commit, DispatchOptions, Store } from "vuex";

import { translate } from "@i18n/instance";
import actions from "@store/actions";
import { ActionTypes } from "@store/api";

export type Errors = {
    [key: string]: string[];
};

export type FormattedErrors = {
    [key: string]: string;
};

// transforms an array of errors into a string
export const formatErrors = (errors: Errors = {}): FormattedErrors => {
    return mapValues((list) => {
        return castArray(list).join("\n");
    }, errors);
};

// if P type (from store action definition) contains "data" (ex: when using Pagination<T>) consider the return type
// to be that full object, otherwise consider the return type to be contained in a "data" key
type BulkSuccessPayload<P> = P extends { data: unknown } ? P : { data: P };

type BulkFailPayload = {
    error: string;
    errors: FormattedErrors;
};

export type BulkResult<P> = {
    fail?: BulkFailPayload;
    success?: BulkSuccessPayload<P>;
    status?: number;
};

type ApiActions = typeof actions;

type UnpackPromise<T> = T extends Promise<infer U> ? U : T;

export type BulkAction = <K extends keyof ApiActions, A extends ApiActions[K]>(
    key: K,
    // if no parameter is asked, the only needed argument is key, otherwise it's key + data + optional options
    ...params: Parameters<ApiActions[K]>[1] extends undefined ? [] : [Parameters<ApiActions[K]>[1], DispatchOptions?]
) => Promise<BulkResult<UnpackPromise<ReturnType<ApiActions[K]>>>>;

class Bulk {
    private _store!: Store<any>;

    init(store: Store<any>) {
        this._store = store;
    }

    start(type: ActionTypes): string {
        return `${type}/start`;
    }

    success(type: ActionTypes): string {
        return `${type}/success`;
    }

    fail(type: ActionTypes): string {
        return `${type}/fail`;
    }

    private commitIfExists(type: string, ...props: any): ReturnType<Commit> {
        // _mutations is not in the public API, but exists.
        // NOTE: Take care of this line for the migration Vue 3
        const mutation = (this._store as any)._mutations[type];

        if (!mutation) {
            return;
        }

        return this._store.commit(type, ...props);
    }

    async commit(type: ActionTypes, promise: Promise<{ data: any }>): Promise<any> {
        this.commitIfExists(this.start(type), promise);

        try {
            const { data } = await promise;

            this.commitIfExists(this.success(type), data);

            return {
                success: data,
            };
        } catch (error: any) {
            // sometimes we get an error in unexpected format, log to gather debug info
            if (!error.response) {
                console.error("Monitoring - Bulk error", error);
            }

            // extract message and errors, give default values if the response has an unexpected format
            const {
                data: { errors, message },
                status,
            } = error.response || ({ data: {} } as any);
            // return validation errors
            const payload: BulkFailPayload = {
                error: message || translate("shared.utils.bulk.error"),
                errors: formatErrors(errors || {}),
            };

            this.commitIfExists(this.fail(type), payload);

            return {
                fail: payload,
                status,
            };
        }
    }
}

const bulk = new Bulk();

export default bulk;
