import { deburr, keyBy, sortBy } from "lodash/fp";

import { translate } from "@i18n/instance";

const getThematics = () => {
    return sortBy(
        (thematic) => {
            return deburr(thematic.label);
        },
        <const>[
            {
                color: "blue",
                icon: "paw",
                image: "https://static.vendredi.cc/assets/icons/thematics/animal-protection.png",
                label: translate("shared.utils.thematics.animalProtection"),
                value: "THEMATIC_ANIMAL_PROTECTION",
            },
            {
                color: "green",
                icon: "comment",
                image: "https://static.vendredi.cc/assets/icons/thematics/civic-life.png",
                label: translate("shared.utils.thematics.civicLife"),
                value: "THEMATIC_CIVIC_LIFE",
            },
            {
                color: "orange",
                icon: "fist-raised",
                image: "https://static.vendredi.cc/assets/icons/thematics/combating-discrimination.png",
                label: translate("shared.utils.thematics.combatingDiscrimination"),
                value: "THEMATIC_COMBATING_DISCRIMINATION",
            },
            {
                color: "green",
                icon: "archway",
                image: "https://static.vendredi.cc/assets/icons/thematics/culture.png",
                label: translate("shared.utils.thematics.culture"),
                value: "THEMATIC_CULTURE",
            },
            {
                color: "yellow",
                icon: "graduation-cap",
                image: "https://static.vendredi.cc/assets/icons/thematics/education.png",
                label: translate("shared.utils.thematics.education"),
                value: "THEMATIC_EDUCATION",
            },
            {
                color: "green",
                icon: "suitcase",
                image: "https://static.vendredi.cc/assets/icons/thematics/employment.png",
                label: translate("shared.utils.thematics.employment"),
                value: "THEMATIC_EMPLOYMENT",
            },
            {
                color: "orange",
                icon: "user-chart",
                image: "https://static.vendredi.cc/assets/icons/thematics/entrepreneurship.png",
                label: translate("shared.utils.thematics.entrepreneurship"),
                value: "THEMATIC_ENTREPRENEURSHIP",
            },
            {
                color: "yellow",
                icon: "leaf",
                image: "https://static.vendredi.cc/assets/icons/thematics/environment.png",
                label: translate("shared.utils.thematics.environment"),
                value: "THEMATIC_ENVIRONMENT",
            },
            {
                color: "blue",
                icon: "venus-mars",
                image: "https://static.vendredi.cc/assets/icons/thematics/gender-equality.png",
                label: translate("shared.utils.thematics.genderEquality"),
                value: "THEMATIC_GENDER_EQUALITY",
            },
            {
                color: "blue",
                icon: "wheelchair",
                image: "https://static.vendredi.cc/assets/icons/thematics/handicap.png",
                label: translate("shared.utils.thematics.handicap"),
                value: "THEMATIC_HANDICAP",
            },
            {
                color: "green",
                icon: "hand-heart",
                image: "https://static.vendredi.cc/assets/icons/thematics/health.png",
                label: translate("shared.utils.thematics.health"),
                value: "THEMATIC_HEALTH",
            },
            {
                color: "orange",
                icon: "soup",
                image: "https://static.vendredi.cc/assets/icons/thematics/nutrition.png",
                label: translate("shared.utils.thematics.nutrition"),
                value: "THEMATIC_NUTRITION",
            },
            {
                color: "yellow",
                icon: "home-heart",
                image: "https://static.vendredi.cc/assets/icons/thematics/poverty.png",
                label: translate("shared.utils.thematics.poverty"),
                value: "THEMATIC_POVERTY",
            },
            {
                color: "blue",
                icon: "universal-access",
                image: "https://static.vendredi.cc/assets/icons/thematics/refugees.png",
                label: translate("shared.utils.thematics.refugees"),
                value: "THEMATIC_REFUGEES",
            },
            {
                color: "orange",
                icon: "globe-europe",
                image: "https://static.vendredi.cc/assets/icons/thematics/solidarity.png",
                label: translate("shared.utils.thematics.solidarity"),
                value: "THEMATIC_SOLIDARITY",
            },
        ]
    );
};

export type Thematic = ReturnType<typeof getThematics>[number]["value"];

const getters = () => {
    const thematics = getThematics();

    return {
        thematicByValue: keyBy("value", thematics),
        thematics,
    };
};

export default getters;
