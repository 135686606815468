import _Vue from "vue";

export function lightbox(this: _Vue) {
    return {
        close: () => {
            this.$root.$emit("lightbox:close");
        },
        open: (data: object) => {
            this.$root.$emit("lightbox:open", data);
        },
    };
}

export default {
    install(Vue: typeof _Vue) {
        Vue.prototype.$lightbox = lightbox;
    },
};
