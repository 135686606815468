/* eslint-disable sort-keys */

import { debounce } from "lodash";
import { isEqual, mapKeys, mapValues, pick, snakeCase } from "lodash/fp";
import { Route } from "vue-router";

import { ContextType } from "../plugins/context";

/**
 * Tracking with Amplitude
 * `window.amplitude` comes from Amplitude CDN
 */
const Analytics: Analytics.Tracker & {
    route: {
        app: ContextType;
        page: string;
        params?: {
            [key: string]: string | number;
        };
        tab?: string;
        url: string;
    } | null;

    getContext(): {
        association_slug?: string;
        company_slug?: string;
        type: ContextType;
    } | null;
} = {
    // store the current route the user is on, useful to locate the user when tracking events
    route: null,

    // when a page is opened (can have a sub tab selected too)
    // ex: Analytics.page("programs.show", "missions", { program_id: 12 });
    trackPage(page, tab, params = {}) {
        const context = Analytics.getContext();

        // untracked app
        if (!context) {
            return;
        }

        if (window.amplitude) {
            window.amplitude.getInstance("v2").logEvent("Page Viewed", { context, route: Analytics.route });
        }
    },

    // when an event is tracked
    // an event is "Object Action" (action is past tense) with first letter capitalized (ex: "Page Viewed", "Quest Archived", "User invited", "Missions Searched", etc.)
    // ex: Analytics.trackEvent("Mission Updated", { mission_id: 12 });
    trackEvent(event, props = {}) {
        // clean passed props to only keep JSON compatible data
        const sent = JSON.parse(JSON.stringify(props));
        const context = Analytics.getContext();

        // untracked app
        if (!context) {
            return;
        }

        if (window.amplitude) {
            window.amplitude.getInstance("v2").logEvent(event, { ...sent, context, route: Analytics.route });
        }
    },

    // returns information about the current app context and concerned company/association
    // passed with all event so we can filter them more precisely
    getContext() {
        const employeeMatch = window.location.pathname.match(/^\/collaborateur\/([a-z0-9-]*)/);

        // employee context
        if (employeeMatch) {
            return {
                company_slug: employeeMatch[1],
                type: ContextType.employee,
            };
        }

        const companyMatch = window.location.pathname.match(/^\/entreprise\/([a-z0-9-]*)/);

        // company context
        if (companyMatch) {
            return {
                company_slug: companyMatch[1],
                type: ContextType.company,
            };
        }

        const associationMatch = window.location.pathname.match(/^\/association\/([a-z0-9-]*)/);

        // association context
        if (associationMatch) {
            return {
                association_slug: associationMatch[1],
                type: ContextType.association,
            };
        }

        const adminMatch = window.location.pathname.match(/^\/admin/);

        // admin context
        if (adminMatch) {
            return {
                type: ContextType.admin,
            };
        }

        // otherwise public context
        return {
            type: ContextType.public,
        };
    },
};
const debouncedTrackPage = debounce(Analytics.trackPage, 500);
// query params that should be considered as "a new page" event for Analytics when changing
// ex: "bar_tab" changes => consider it as a new page
// ex: "term" changes (because of a search bar) => dont consider it as a new page, don't track that
const TRACKED_QUERY_PARAMS = ["bar_tab"];
// build an object of properties that matter in route
// if one of those properties changes, then we can consider the route as changing
const getRouteCheck = (route: Route): object => {
    const trackedQuery = pick(TRACKED_QUERY_PARAMS, route.query);

    return {
        name: route.name,
        query: trackedQuery,
    };
};

// send analytics from SPA routers on each page change
export const routeMiddleware = (to: Route, from: Route): void => {
    const toRouteCheck = getRouteCheck(to);
    const fromRouteCheck = getRouteCheck(from);

    // if the route is not changing from previous one, don't track it
    // ex: afterEach will be triggered if we do a search in the page because it could add "term" query param, which we don't want to track as "new page navigation"
    if (isEqual(toRouteCheck, fromRouteCheck)) {
        return;
    }

    const context = Analytics.getContext();

    // untracked app
    if (!context) {
        return;
    }

    // send route params to Analytics
    const params: {
        [key: string]: string | number;
    } = mapKeys(
        // keys are snake case
        // ex: "missionId" => "mission_id"
        snakeCase,
        // values that should be numbers are converted
        // ex: { mission_id: "12" } => { mission_id: 12 }
        mapValues((value) => {
            const number = parseInt(value);

            return isNaN(number) ? value : number;
        }, to.params)
    );
    const page = to.name as string;
    const tab = (to.query.bar_tab || undefined) as string | undefined;

    // save current route to send it along all other tracked events
    Analytics.route = {
        app: context.type,
        page,
        params,
        tab,
        url: to.path,
    };

    // track page change
    // debounce page tracking to prevent redirection spamming (when we redirect we only want to consider the last page visited)
    debouncedTrackPage(page, tab, params);
};

// send analytics for non-SPA pages (for pages starting from blade.php files)
export const trackLegacyPage = (page: string): void => {
    const context = Analytics.getContext();

    // untracked app
    if (!context) {
        return;
    }

    Analytics.route = {
        app: context.type,
        page,
        url: window.location.pathname,
    };

    Analytics.trackPage(page);
};

export default Analytics;
