<template>
    <i
        v-b-tooltip="{ title: tooltip, placement: placement }"
        class="fa-fw flex-shrink-0"
        :class="[
            `fa-${icon}`,
            variant,
            {
                'fa-spin': spin,
                [`fa-${size}`]: size,
            },
        ]"
        @click="handleClick"
    />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

type Placement = "top" | "bottom" | "left" | "right";

export default Vue.extend({
    props: {
        brand: Boolean,
        icon: {
            required: true,
            type: String,
        },
        placement: {
            default: "top",
            type: String as PropType<Placement>,
        },
        size: String,
        solid: Boolean,
        spin: Boolean,
        tooltip: String,
    },
    computed: {
        variant(): string {
            if (this.brand) {
                return "fab";
            }

            if (this.solid) {
                return "fas";
            }

            return "far";
        },
    },
    methods: {
        handleClick(): void {
            this.$emit("click");
        },
    },
});
</script>
