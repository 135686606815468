import "moment/locale/ar";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/pt";
import "moment/locale/ru";
import "moment/locale/zh-cn";

import { ExtraErrorData } from "@sentry/integrations";
import * as Sentry from "@sentry/vue";
import { BootstrapVue } from "bootstrap-vue";
import { registerLocale } from "i18n-iso-countries";
import countriesAr from "i18n-iso-countries/langs/ar.json";
import countriesDe from "i18n-iso-countries/langs/de.json";
import countriesEn from "i18n-iso-countries/langs/en.json";
import countriesEs from "i18n-iso-countries/langs/es.json";
import countriesFr from "i18n-iso-countries/langs/fr.json";
import countriesIt from "i18n-iso-countries/langs/it.json";
import countriesNl from "i18n-iso-countries/langs/nl.json";
import countriesPt from "i18n-iso-countries/langs/pt.json";
import countriesRu from "i18n-iso-countries/langs/ru.json";
import countriesZh from "i18n-iso-countries/langs/zh.json";
import { updateLocale } from "moment";
import Vue from "vue";
import VueDOMPurifyHTML from "vue-dompurify-html";
import VueEllipseProgress from "vue-ellipse-progress";
import VueLineClamp from "vue-line-clamp";
import Toasted from "vue-toasted";
import VueWindowSizePlugin from "vue-window-size";
import * as VueGoogleMaps from "vue2-google-maps";

import vueI18nInstance from "@i18n/instance";
import CompanyFeaturePlugin from "@plugins/company-feature";
import ScrollToFirstErrorPlugin from "@plugins/scrollToFirstError";

import ContextPlugin from "./plugins/context";
import LightboxPlugin from "./plugins/lightbox";
import LinkPlugin from "./plugins/link";
import PanelPlugin from "./plugins/panel";
import PermissionPlugin from "./plugins/permission";
import RolePlugin from "./plugins/roles";
import SPA from "./spa";
import Analytics from "./utils/analytics";

window.Analytics = Analytics;
window.Sentry = Sentry as any;
window.Vue = Vue as any;

// plug in Sentry to Vue
if (process.env.EXCLUDE_EXTERNAL_SERVICES !== "true") {
    Sentry.init({
        Vue,
        dsn: process.env.SENTRY_PUBLIC_DSN,
        integrations: [new Sentry.BrowserTracing(), new ExtraErrorData()],
        tracesSampleRate: Number(process.env.SENTRY_TRACES_SAMPLE_RATE || 0),
        ignoreErrors: [
            // ignore vue-router errors that are not real errors
            "Navigation cancelled",
            "Navigation duplicated",
            "NavigationDuplicated",
            "Redirected when going from",
            "ChunkLoadError",
            // ignore Intercom missing
            "Intercom is not defined",
            // error from "outlook safe-link crawler" bot, we can ignore
            // (see https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/7)
            "Non-Error promise rejection captured with value: Object Not Found Matching Id:",

            // Random plugins/extensions
            "top.GLOBALS",
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            "originalCreateNotification",
            "canvas.contentDocument",
            "MyApp_RemoveAllHighlights",
            "http://tt.epicplay.com",
            "Can't find variable: ZiteReader",
            "jigsaw is not defined",
            "ComboSearch is not defined",
            "http://loading.retry.widdit.com/",
            "atomicFindClose",
            // Facebook borked
            "fb_xd_fragment",
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            "bmi_SafeAddOnload",
            "EBCallBackMessageReceived",
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            "conduitPage",
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
    });
}

// test de modification des hashs moment
updateLocale("fr", {
    calendar: {
        lastDay: "[Hier]",
        lastWeek: "dddd [dernier]",
        nextDay: "[Demain]",
        nextWeek: "dddd",
        sameDay: "[Aujourd'hui]",
        sameElse: "L",
    },
    longDateFormat: {
        LT: "H[h]mm", // customized
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY H[h]mm",
        LLLL: "dddd D MMMM YYYY H[h]mm",
    },
});

// add languages for countries list
registerLocale(countriesAr);
registerLocale(countriesDe);
registerLocale(countriesEn);
registerLocale(countriesEs);
registerLocale(countriesFr);
registerLocale(countriesIt);
registerLocale(countriesNl);
registerLocale(countriesPt);
registerLocale(countriesRu);
registerLocale(countriesZh);

Vue.use(VueLineClamp);

Vue.use(VueWindowSizePlugin);

Vue.use(VueEllipseProgress);

/**
 * formats a phone number.
 * Example: 123-456-7890 => (123) 456-7890
 */
Vue.filter("phone", (phone: string): string => {
    return phone.replace(/[^0-9]/g, "").replace(/(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, "+$1 $2 $3 $4 $5 $6");
});

Vue.filter("capitalize", (value: string): string => {
    if (!value) {
        return "";
    }

    value = value.toString();

    return value.charAt(0).toUpperCase() + value.slice(1);
});

// notifications
Vue.use(Toasted);

// bootstrap
Vue.use(BootstrapVue);

// google maps lib
Vue.use(VueGoogleMaps);

// DOM Purify attr
Vue.use(VueDOMPurifyHTML, {
    default: {
        ADD_ATTR: ["target"],
    },
    namedConfigurations: {
        b: {
            ALLOWED_TAGS: ["b"],
        },
    },
});

// bind default French translator for public pages
window.i18n = vueI18nInstance.i18n;

// sPA
Vue.use(ContextPlugin);
Vue.use(LinkPlugin);
Vue.use(CompanyFeaturePlugin);
Vue.use(PermissionPlugin);
Vue.use(RolePlugin);

window.CompanyFeaturePlugin = CompanyFeaturePlugin;
window.ContextPlugin = ContextPlugin;
window.LinkPlugin = LinkPlugin;
window.PermissionPlugin = PermissionPlugin;

window.SPA = SPA;

Vue.use(PanelPlugin);
Vue.use(LightboxPlugin);
Vue.use(ScrollToFirstErrorPlugin);
