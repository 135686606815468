<template>
    <transition name="fade">
        <div v-if="visible" class="alert-padding">
            <div class="alert alert-dismissible show" :class="classAlert">
                <button class="close" type="button" @click="closeAlert">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span>{{ content }}</span>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        content: { required: true, type: String },
        important: { default: false, type: Boolean },
        type: { required: true, type: String },
    },
    data() {
        return {
            visible: true,
        };
    },
    mounted() {
        if (!this.important) {
            setTimeout(this.closeAlert, 5000);
        }
    },
    computed: {
        classAlert(): string {
            const classToAdd = ` alert-${this.type}`;

            return classToAdd;
        },
    },
    methods: {
        closeAlert(): void {
            this.visible = false;
        },
    },
});
</script>
