<template>
    <b-sidebar
        backdrop
        no-close-on-route-change
        no-enforce-focus
        no-header
        :right="vertical ? null : 'right'"
        :sidebar-class="vertical ? 'vertical' : ''"
        text-variant=""
        :visible="isOpen"
        :width="fullscreen ? '100%' : '800px'"
        @hidden="handleClose"
        @shown="handleShown"
    >
        <div v-if="!vertical" class="panel-close" @click="handleClose">
            <icon class="m-auto" icon="times" size="2x" />
        </div>

        <div v-if="isLoading" class="align-items-center d-flex h-100 justify-content-center">
            <b-spinner />
        </div>

        <div v-else :class="bodyClass">
            <slot :close="handleClose" />
        </div>

        <template v-if="$slots.footer" #footer>
            <slot name="footer" />
        </template>
    </b-sidebar>
</template>

<style lang="scss" scoped>
@import "mixins";
@import "variables";

.panel {
    overflow-x: hidden;

    &-close {
        position: absolute;
        left: -35px;
        background-color: $color-primary-basic;
        width: 35px;
        height: 35px;
        color: white;
        margin: auto;
        display: flex;
        align-content: center;
        justify-content: center;
        cursor: pointer;

        @include media-below("md") {
            left: 0;
        }
    }
}

::v-deep .b-sidebar:not(.b-sidebar-right).slide:not(.show).vertical {
    transform: translateY(100%) !important;
}
</style>

<script lang="ts">
import { omit } from "lodash";
import Vue, { PropType } from "vue";

import { PanelName } from "@plugins/panel";
import { Id } from "@store/resources/Api";

const escKey = "Escape";

export default Vue.extend({
    props: {
        bodyClass: String,
        fullscreen: Boolean,
        isLoading: Boolean,
        name: {
            required: true,
            type: String as PropType<PanelName>,
        },
        vertical: Boolean,
    },
    data() {
        const queryName = `panel-${this.name}`;

        return {
            data: null as any,
            isOpen: false,
            queryName,
        };
    },
    mounted() {
        document.addEventListener("keyup", this.keyupEvent);

        this.$root.$on("panel:open", this.handleOpenEvent);

        this.$root.$on("panel:close", this.handleCloseEvent);

        const id = Number(this.$route.query[this.queryName]);

        if (id) {
            this.handleOpen(id);
        }
    },
    beforeDestroy() {
        document.removeEventListener("keyup", this.keyupEvent);

        this.$root.$off("panel:open", this.handleOpenEvent);

        this.$root.$off("panel:close", this.handleCloseEvent);

        this.handleClose();
    },
    watch: {
        query(id) {
            // close panel if removed from url query
            if (this.isOpen && !id) {
                this.handleClose();
            }

            // open panel if appears in url query
            if (!this.isOpen && id) {
                this.handleOpen(id);
            }
        },
    },
    computed: {
        query(): number | null {
            const query = this.$route.query[this.queryName] as string;

            return query ? Number(query) : null;
        },
    },
    methods: {
        handleClose() {
            if (!this.isOpen) {
                return;
            }

            // Reset scroll behind panel
            document.body.classList.remove("body-not-scrollable");

            this.isOpen = false;
            this.handleUpdateRoute();
            this.$emit("close");
        },
        handleCloseEvent(name: PanelName) {
            if (name === this.name && this.isOpen) {
                this.handleClose();
            }
        },
        handleOpen(id: Id | null, data?: object | null) {
            if (this.isOpen) {
                return;
            }

            this.isOpen = true;

            // Prevent scroll behind panel
            document.body.classList.add("body-not-scrollable");

            // emitted value when opening is either panel id (sync with url) or data object
            const value = id || data;

            this.$emit("open", value);

            Analytics.trackEvent("Panel Viewed", {
                id,
                name: this.name,
            });
        },
        handleOpenEvent(name: PanelName, id: Id | null, data: object | null) {
            if (this.isOpen) {
                this.handleClose();
            }

            if (name === this.name) {
                // open panel by id or passed data
                this.handleOpen(id, data);
                this.handleUpdateRoute(id);
            }
        },
        handleShown() {
            this.$emit("shown");
        },
        handleUpdateRoute(id: Id | null = null): void {
            const currentQuery = this.$route.query[this.queryName] || null;
            const nextQuery = id ? String(id) : null;

            // there is a panel to open and it should be set in url
            if (nextQuery) {
                // if already opened, dont push same route again
                if (nextQuery === currentQuery) {
                    return;
                }

                // otherwise add panel to url
                const query = {
                    ...this.$route.query,
                    [this.queryName]: nextQuery,
                };

                this.$router.push({ query });
            }
            // otherwise there is no panel to open and it should be removed from url
            else {
                // if already closed, dont push same route again
                if (nextQuery === currentQuery) {
                    return;
                }

                // otherwise remove panel from url
                const query = omit(this.$route.query, this.queryName);

                this.$router.push({ query });
            }
        },
        keyupEvent(event: KeyboardEvent) {
            if (event.key === escKey) {
                this.handleClose();
            }
        },
    },
});
</script>
