import Vue from "vue";

import { Link } from "@types";
import SharedRouter, { SharedRoute, SharedRouterOptions } from "@utils/sharedRouter";

import ContextPlugin from "./context";
import PermissionPlugin from "./permission";

class LinkPlugin {
    private $context: ContextPlugin;
    private $permission: PermissionPlugin;

    constructor($context: ContextPlugin, $permission: PermissionPlugin) {
        this.$context = $context;
        this.$permission = $permission;
    }

    static install(vue: typeof Vue): void {
        vue.mixin({ beforeCreate: this.init });
    }

    static init(
        this: Vue & {
            // plugin can be passed itself as option when adding plugin to Vue
            $options: {
                link: LinkPlugin;
            };
        }
    ): void {
        const options = this.$options;

        // injection in root
        if (options.link) {
            this.$link = options.link;
        }

        // get from parent
        else if (options.parent && options.parent.$link) {
            this.$link = options.parent.$link;
        }
    }

    route(name: SharedRoute, item: any, options?: SharedRouterOptions): Link {
        return SharedRouter.link(this.$context, this.$permission, name, item, options);
    }
}

export default LinkPlugin;
