<template>
    <div class="c-tab-bar">
        <div
            v-for="tab in tabs"
            :key="tab.value"
            class="c-tab-bar__tab"
            :class="[currentTab === tab.value ? 'active' : 'inactive']"
            :data-cy="tab.data_cy"
            :title="tab.label"
            @click="changeTab(tab.value)"
        >
            {{ tab.label }}
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import { Tab } from "@components/shared/Tabs.vue";

export default Vue.extend({
    props: {
        activeTab: String,
        noUrl: Boolean,
        tabs: {
            required: true,
            type: Array as PropType<Tab[]>,
        },
    },
    data() {
        let currentTab = (this.$route.query.bar_tab as string) || this.tabs[0].value;

        // if "noUrl" mode, just take the activeTab as currentTab
        if (this.noUrl && this.activeTab) {
            currentTab = this.activeTab;
        }

        return {
            currentTab,
        };
    },
    mounted() {
        if (this.noUrl) {
            return;
        }

        this.changeTab(this.currentTab);
    },
    watch: {
        "$route.query.bar_tab"(barTab: string) {
            if (this.currentTab !== barTab && !this.noUrl) {
                this.changeTab(barTab);
            }
        },
    },
    methods: {
        changeTab(tabValue: string): void {
            this.currentTab = tabValue;

            this.$emit("updated", tabValue);

            if (this.noUrl) {
                return;
            }

            if (!this.$route.query.bar_tab) {
                this.$router.replace({
                    query: {
                        bar_tab: this.currentTab,
                    },
                });
            }

            if (this.currentTab !== this.$route.query.bar_tab) {
                this.$router.push({
                    query: {
                        bar_tab: this.currentTab,
                    },
                });
            }
        },
    },
});
</script>
