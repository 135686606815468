import Vue from "vue";

import { Publisher } from "@store/resources";

export enum ContextType {
    admin = "admin",
    association = "association",
    company = "company",
    employee = "employee",
    public = "public",
}

export const defaultContext = ContextType.public;

class ContextPlugin {
    constructor(private readonly context: ContextType) {}

    static install(vue: typeof Vue): void {
        vue.mixin({ beforeCreate: this.init });
    }

    static init(
        this: Vue & {
            // plugin can be passed itself as option when adding plugin to Vue
            $options: {
                context: ContextPlugin;
            };
        }
    ): void {
        const options = this.$options;

        // injection in root
        if (options.context) {
            this.$context = options.context;
        }

        // get from parent
        else if (options.parent && options.parent.$context) {
            this.$context = options.parent.$context;
        }
    }

    getType(): ContextType {
        return this.context;
    }

    isAdminContext(): boolean {
        return this.getType() === ContextType.admin;
    }

    isAssociationContext(): boolean {
        return this.getType() === ContextType.association;
    }

    isCompanyContext(): boolean {
        return this.getType() === ContextType.company;
    }

    isEmployeeContext(): boolean {
        return this.getType() === ContextType.employee;
    }

    isPublicContext(): boolean {
        return this.getType() === ContextType.public;
    }

    // returns true if the passed publisher corresponds to current context
    // ex: in company context: returns true if looking at a company mission but false to association mission
    isCurrentPublisher(publisher: Publisher): boolean {
        const { type } = publisher;

        if (this.isAdminContext()) {
            return type === "PUBLISHER_TYPE_ADMIN";
        }

        if (this.isAssociationContext()) {
            return type === "PUBLISHER_TYPE_ASSOCIATION";
        }

        if (this.isCompanyContext()) {
            return type === "PUBLISHER_TYPE_COMPANY";
        }

        return false;
    }
}

export default ContextPlugin;
