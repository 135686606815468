<template>
    <div
        class="shared-logo"
        :style="{
            ...background,
            height: width,
            width: width,
        }"
    >
        <img
            v-if="showAsCertifiedLarge"
            alt="Certified"
            class="certified-large"
            src="https://static.vendredi.cc/assets/pictos/certified.svg"
        />
        <img
            v-else-if="showAsCertified"
            alt="Certified"
            class="certified-small"
            src="https://static.vendredi.cc/assets/pictos/certified.svg"
        />
    </div>
</template>

<style lang="scss" scoped>
@import "variables";

.shared-logo {
    position: relative;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: $color-white;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $color-border;

    &.cover {
        background-size: cover;
    }
    .certified {
        &-small {
            position: absolute;
            top: -12px;
            right: -12px;
            width: 24px;
            height: 24px;
        }

        &-large {
            position: absolute;
            top: -15px;
            right: -15px;
            width: 30px;
            height: 30px;
        }
    }
}
</style>

<script lang="ts">
import Vue, { PropType } from "vue";

enum SizeToPx {
    xs = "20px",
    sm = "42px",
    md = "64px",
    lg = "100px",
    xl = "170px",
}

type BackgroundCss = {
    backgroundImage?: string;
    backgroundColor?: string;
};

export default Vue.extend({
    props: {
        certified: Boolean,
        size: {
            default: "md" as keyof typeof SizeToPx,
            type: String as PropType<keyof typeof SizeToPx>,
        },
        url: String,
    },
    computed: {
        background(): BackgroundCss {
            if (this.url) {
                return { backgroundImage: `url(${this.imageUrl}` };
            }

            return { backgroundColor: "#F2F4F7" };
        },
        imageUrl(): string {
            return `${this.url}?size=sm`;
        },
        // companies have a little Vendredi badge on certified associations logos
        showAsCertified(): boolean {
            if (!this.$context) {
                return false;
            }

            if (this.$context.isEmployeeContext()) {
                return false;
            }

            if (this.$context.isPublicContext()) {
                return false;
            }

            return this.certified;
        },
        showAsCertifiedLarge(): boolean {
            return this.showAsCertified && ["lg", "xl"].includes(this.size);
        },
        width(): string {
            return SizeToPx[this.size];
        },
    },
});
</script>
