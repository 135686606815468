import { max, words } from "lodash/fp";

import { strip } from "./markdown";

// compute reading time
export const readingTime = (text: string): number => {
    const wordsPerMinute = 300;
    const computedTime = (words(text).length * 60000) / wordsPerMinute;

    return max([5000, computedTime]) as number;
};

// type = "success" | "info" | "error"
const notify = (message: string, type: "info" | "success" | "error" = "info", duration: number | null = null): void => {
    const { Vue } = window;

    if (!Vue) {
        throw new Error("Vue is not instantiated yet");
    }

    if (!Vue.toasted) {
        throw new Error("Vue Toasted does not exist");
    }

    Vue.toasted.show(strip(message), {
        duration: duration || readingTime(message),
        iconPack: "fontawesome",
        position: "top-center",
        type,
    });
};

export default notify;
