<template>
    <Layout>
        <div class="justify-content-center text-center mb-4">
            <Logo :url="association.logo" class="ml-auto mr-auto mb-5 border-0" />
            <h1>{{ $t("public.association.join.title") }}</h1>
            <div>{{ $t("public.association.join.subtitle", { association: association.name }) }}</div>
        </div>

        <form ref="form" method="post" :action="actions.register" class="row">
            <input type="hidden" name="_token" :value="csrfToken" />

            <div class="col-md-6">
                <Field :label="$t('public.association.join.first_name.label')" required>
                    <b-input
                        type="text"
                        v-model="form.first_name"
                        name="first_name"
                        required
                        :placeholder="$t('public.association.join.first_name.placeholder')"
                        data-cy="input_first_name"
                    />
                </Field>
            </div>
            <div class="col-md-6">
                <Field :label="$t('public.association.join.last_name.label')" required>
                    <b-input
                        type="text"
                        v-model="form.last_name"
                        name="last_name"
                        required
                        :placeholder="$t('public.association.join.last_name.placeholder')"
                        data-cy="input_last_name"
                    />
                </Field>
            </div>

            <div class="col-md-6">
                <Field :label="$t('public.association.join.email.label')" required>
                    <b-input
                        type="email"
                        :placeholder="$t('public.association.join.email.placeholder')"
                        readonly
                        required
                        :value="fixedEmail"
                        data-cy="input_email"
                    />
                </Field>
            </div>

            <div class="col-md-6">
                <Field :label="$t('public.association.join.phone.label')" required>
                    <b-input
                        type="text"
                        v-model="form.phone"
                        name="phone"
                        required
                        :placeholder="$t('public.association.join.phone.placeholder')"
                        data-cy="input_phone"
                    />
                </Field>
            </div>

            <div class="col-md-6">
                <Field :label="$t('public.association.join.password.label')" required>
                    <PasswordInput
                        v-model="form.password"
                        name="password"
                        required
                        :class="{ 'is-invalid': $v.form.password.$error }"
                        @input="$v.form.password.$touch"
                        data-cy="input_password"
                    />
                </Field>
            </div>

            <div class="col-md-6">
                <Field
                    :label="$t('public.association.join.passwordConfirmation.label')"
                    required
                    :error="
                        $v.form.password_confirmation.$error
                            ? $t('public.association.join.passwordConfirmation.noMatch')
                            : null
                    "
                >
                    <PasswordInput
                        v-model="form.password_confirmation"
                        name="password_confirmation"
                        required
                        :class="{ 'is-invalid': $v.form.password_confirmation.$error }"
                        @input="$v.form.password_confirmation.$touch"
                        data-cy="input_password_confirmation"
                    />
                </Field>
            </div>

            <small class="col-12 mt-n4 mb-5 text-muted">{{ $t("shared.helpers.passwordRequirements") }}</small>

            <div class="col-12" v-if="canSelectABranch">
                <Field label="Antenne">
                    <b-select v-model="form.branch_id" name="branch_id">
                        <option selected value="">{{ $t("public.association.join.branch.emptyOption") }}</option>
                        <option v-for="branch in branches" :key="branch.id" :value="branch.id">
                            {{ branch.name }}
                        </option>
                    </b-select>

                    <template #help>
                        <SelectBranchHelp :supervisors="association.supervisors" />
                    </template>
                </Field>
            </div>

            <div class="col-12">
                <Field required>
                    <b-checkbox
                        v-model="form.terms_of_use_accepted"
                        name="terms_of_use_accepted"
                        required
                        data-cy="checkbox_terms_of_use_accepted"
                    >
                        <span
                            v-dompurify-html="
                                $t('public.association.join.acceptTermsOfUse', {
                                    url: actions.terms_of_use,
                                })
                            "
                        />
                    </b-checkbox>
                </Field>
            </div>

            <div class="d-flex justify-content-around align-items-center col-12 mb-5">
                <div class="form-group">
                    <b-button variant="primary" type="submit" data-cy="button_submit_association">
                        {{ $t("public.association.join.register") }}
                    </b-button>
                </div>
            </div>

            <div class="col-12 text-center">
                <span>{{ $t("public.association.join.providedBy") }}</span>
                <img src="https://static.vendredi.cc/assets/logos/vendredi-full.png?size=sm" class="img" width="90" />
            </div>
        </form>
    </Layout>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";

import Field from "@components/forms/Field.vue";
import LoginLogo from "@components/shared/LoginLogo.vue";
import Logo from "@components/shared/Logo.vue";
import { Association } from "@store/resources";
import passwordValidator from "@validator/password";

import Layout from "./Layout.vue";
import ExternalRouter from "@utils/externalRouter";
import PasswordInput from "@components/forms/PasswordInput.vue";
import Branch, { BranchLight } from "@store/resources/Branch";
import MultiSelect, { MultiSelectOption } from "@components/forms/MultiSelect.vue";
import SelectBranchHelp from "@components/associations/SelectBranchHelp.vue";

export default Vue.extend({
    components: { Field, Layout, LoginLogo, Logo, MultiSelect, PasswordInput, SelectBranchHelp },
    mixins: [validationMixin],
    props: {
        actions: {
            required: true,
            type: Object,
        },
        association: {
            required: true,
            type: Object as PropType<Association>,
        },
        branches: {
            required: true,
            type: Array as PropType<BranchLight[]>,
        },
        fixedEmail: {
            default: null,
            type: String,
        },
        invitationBranch: {
            default: null,
            type: Object as PropType<Branch>,
        },
    },
    validations() {
        return {
            form: {
                password: {
                    password: passwordValidator,
                    required,
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs("password"),
                },
            },
        };
    },
    data() {
        return {
            csrfToken: window.Laravel as string,
            form: {
                branch_id: this.invitationBranch ? this.invitationBranch.id : "",
                first_name: "",
                last_name: "",
                password: "",
                password_confirmation: "",
                phone: "",
                terms_of_use_accepted: false,
            },
        };
    },
    computed: {
        canSelectABranch(): boolean {
            return this.association.are_branches_activated && this.branches.length;
        },
        eligibilityCriteriaUrl() {
            return ExternalRouter.url("eligibility_criteria");
        },
    },
});
</script>
