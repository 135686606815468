<template>
    <div
        class="forms-field"
        :class="{
            'w-100': fullWidth,
        }"
    >
        <div v-if="label || $slots.label" class="align-items-center d-flex label">
            <label v-if="label" class="form-label h3">
                {{ label }}
                <small v-if="!required" class="font-weight-normal text-muted">
                    • {{ $t("shared.layout.optional") }}
                </small>
                <slot name="tooltip"></slot>
            </label>
            <small
                v-if="maxLength && length"
                :class="{
                    'text-danger': length > maxLength,
                }"
            >
                {{ length }} / {{ maxLength }}
            </small>

            <slot v-else name="label" />

            <slot name="label-help" />
        </div>

        <div :class="{ 'is-invalid': error }">
            <slot />
        </div>

        <FieldError v-if="error">{{ error }}</FieldError>

        <small v-if="help || $slots.help" class="mt-1 text-muted">
            <slot name="help">
                {{ help }}
            </slot>
        </small>

        <small v-else-if="imageHelp">
            <span
                v-dompurify-html="
                    $t('shared.forms.field.imgFormatHelp', {
                        width: imageHelp.width,
                        height: imageHelp.height,
                    })
                "
            />
            <span v-if="showLibrary" v-dompurify-html="$t('shared.forms.field.imgLibraryHelp', { url: helpUrl })" />
            <span v-else v-dompurify-html="$t('shared.forms.field.imgSettingHelp', { url: helpUrl })" />
        </small>

        <slot name="below" />
    </div>
</template>

<style lang="scss" scoped>
.forms-field {
    margin-bottom: 32px;

    .label {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 6px;
    }
}
</style>

<script lang="ts">
import Vue, { PropType } from "vue";

import ExternalRouter from "@utils/externalRouter";

import FieldError from "./FieldError.vue";

type ImageHelp = {
    width: number;
    height: number;
};

export default Vue.extend({
    components: {
        FieldError,
    },
    props: {
        error: [String, Boolean],
        fullWidth: Boolean,
        help: String,
        imageHelp: Object as PropType<ImageHelp>,
        imageHelpCallout: Object as PropType<ImageHelp>,
        label: String,
        length: Number,
        maxLength: Number,
        required: Boolean,
        showLibrary: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        helpUrl(): string {
            if (this.showLibrary) {
                return ExternalRouter.url("doc.image.library");
            }

            return ExternalRouter.url("doc.image.settings");
        },
    },
});
</script>
