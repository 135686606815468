import { trimChars } from "lodash/fp";
import Vue, { CreateElement } from "vue";

import { getCurrentLocale } from "@helpers";
import vueI18nInstance from "@i18n/instance";
import CompanyFeaturePlugin from "@plugins/company-feature";
import { User } from "@store/resources";
import Api from "@utils/api";
import Bulk from "@utils/bulk";
import ExternalRouter from "@utils/externalRouter";

import ContextPlugin, { ContextType } from "./plugins/context";
import LinkPlugin from "./plugins/link";
import PermissionPlugin from "./plugins/permission";

const initializeSPA = async (app: ContextType, initialState?: object, locale?: string) => {
    // webpack optimization: dynamically load stores and routers for the app we want to start
    const [{ default: makeStore }, { default: makeRouter }] = await Promise.all([
        import(/* webpackPreload: true */ `@apps/${app}/store`),
        import(/* webpackPreload: true */ `@apps/${app}/router`),
    ]);
    const store = makeStore(initialState);
    const router = makeRouter(store);

    Object.defineProperty(Vue.prototype, "$api", {
        get() {
            return this.$store.dispatch;
        },
    });

    if (!locale) {
        locale = getCurrentLocale(store.getters.currentUser?.lang);
    }

    const { i18n } = await vueI18nInstance.init(app, locale);

    // if app is association, use unprefixed urls
    // but employee and company apps still use prefixes
    Api.init({
        association: store.getters.currentAssociation,
        company: store.getters.currentCompany,
        type: app,
    });

    Bulk.init(store);

    ExternalRouter.init({
        association: store.getters.currentAssociation,
        company: store.getters.currentCompany,
    });

    const context = new ContextPlugin(app);
    const companyFeature = new CompanyFeaturePlugin(context, store.getters.currentCompany);
    const permission = new PermissionPlugin(store.getters.currentUser, store.getters.currentCompany);
    const link = new LinkPlugin(context, permission);

    return {
        companyFeature,
        context,
        i18n,
        link,
        permission,
        router,
        store,
    };
};

export default {
    // create a full SPA app
    // gets a context and a store initial state (optional)
    // ex: window.SPA.create('company', '#company-app', { ... })
    create: async (app: ContextType, el: string, initialState: object = {}): Promise<Vue> => {
        const { companyFeature, context, i18n, link, permission, router, store } = await initializeSPA(
            app,
            initialState
        );

        return new Vue({
            companyFeature,
            context,
            el,
            i18n,
            link,
            permission,
            router,
            store,
        } as any); // as any needed to fix typing issue with context
    },
    // create a SPA for a single public page
    // gets a component path to load directly in the page and props to give it (and a store initial state (optional) if needed)
    // this is useful when we don't need a store and a router and only want to load a Vue component in a blade file
    // ex: window.SPA.public('#company-app', '/mission/Show.vue', { ... })
    public: async (
        el: string,
        componentPath: string,
        props: Vue.PropOptions = {},
        currentUser?: User
    ): Promise<Vue> => {
        const locale = getCurrentLocale(currentUser?.lang);
        const { context, i18n, link } = await initializeSPA(ContextType.public, {}, locale);
        // remove any `/` that could prefix the path
        const cleanedComponentPath = trimChars("/", componentPath);
        // make an async component factory, then use it for render
        const Component = () => {
            return import(`@apps/public/pages/${cleanedComponentPath}`);
        };

        return new Vue({
            context,
            el,
            i18n,
            link,
            render: (h: CreateElement) => {
                return h(Component, { props: { ...props, currentUser } });
            },
        } as any); // as any needed to fix typing issue with context
    },
};
