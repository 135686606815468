import { Association, Company } from "../store/resources";

type Params = {
    [key: string]: any;
};

type ExternalRoute = {
    props?: string[];
    url: (router: typeof ExternalRouter, params: Params) => string | undefined;
};

export const configs: {
    [key: string]: ExternalRoute;
} = {
    "admin.association": {
        props: ["association"],
        url: (props, { association }) => {
            return `/admin/associations/${association.slug}`;
        },
    },
    "admin.association.list": {
        url: () => {
            return `/admin/associations`;
        },
    },
    "admin.company": {
        props: ["company"],
        url: (props, { company }) => {
            return `/admin/entreprises/${company.slug}`;
        },
    },
    "admin.company.create": {
        url: () => {
            return `/admin/entreprises/creer`;
        },
    },
    "admin.dashboard": {
        url: () => {
            return "/dashboard";
        },
    },
    "admin.engagement": {
        props: ["engagement"],
        url: (props, { engagement }) => {
            return `/admin/engagements/${engagement.id}`;
        },
    },
    "admin.export.tax-receipts": {
        props: ["params"],
        url: (props, { params }) => {
            return `/api/v1/admin/exports/tax-receipts${params}`;
        },
    },
    "admin.export.intermission": {
        props: ["params"],
        url: (props, { params }) => {
            return `/api/v1/admin/exports/intermission?${params}`;
        },
    },
    "admin.impersonate": {
        props: ["user"],
        url: (props, { user }) => {
            return `/impersonate/take/${user.id}`;
        },
    },
    "admin.legal": {
        url: () => {
            return `/admin/actions-operationnelles/documents-juridiques`;
        },
    },
    "admin.shared_associations_view.list": {
        url: () => {
            return `/admin/partage-vues?bar_tab=associationViews`;
        },
    },
    "admin.shared_missions_view.list": {
        url: () => {
            return `/admin/partage-vues?bar_tab=missionViews`;
        },
    },
    "admin.user": {
        props: ["user"],
        url: (props, { user }) => {
            return `/admin/utilisateurs/${user.id}`;
        },
    },
    "admin.taxExemption.show": {
        props: ["taxExemption"],
        url: (props, { taxExemption }) => {
            return `/admin/tax-exemption/${taxExemption.id}`;
        },
    },
    ambassadorKit: {
        url: (props, { lang }) => {
            if (lang === "fr") {
                return "https://vendredi.notion.site/Devenez-un-super-ambassadeur-e03d4eb64a164ad892c7ee383dacedcc";
            }

            return "https://vendredi.notion.site/Becoming-a-super-ambassador-b01d0ab9adc84af7a17569d5cc9923f3";
        },
    },
    launchAmbassadorNetwork: {
        url: (props, { lang }) => {
            if (lang === "fr") {
                return "https://vendredi.notion.site/Lancer-votre-r-seau-d-ambassadeurs-b5815a66748c4b1eb76f994ed127d1a7";
            }

            return "https://vendredi.notion.site/Launch-your-ambassador-program-7615e1dabc8b485ea892cc99d2542f14";
        },
    },
    "api.download_engagement_agreement": {
        url: ({ company, association }, { engagementId }) => {
            if (company) {
                return `/api/v1/companies/${company.slug}/exports/engagements/${engagementId}/engagement-agreement`;
            }

            if (association) {
                return `/api/v1/associations/${association.slug}/exports/engagements/${engagementId}/engagement-agreement`;
            }
        },
    },
    "api.export_achievements": {
        url: ({ company }) => {
            return `/api/v1/companies/${company.slug}/exports/achievements`;
        },
    },
    "api.export_associations": {
        url: () => {
            return `/api/v1/admin/exports/associations`;
        },
    },
    "api.export_challenge_participations": {
        props: ["challenge"],
        url: ({ company }, { challenge }) => {
            return `/api/v1/companies/${company.slug}/exports/challenges/${challenge.id}/participations`;
        },
    },
    "api.export_engagement_timeslots": {
        url: ({ company }) => {
            return `/api/v1/companies/${company.slug}/exports/engagement-timeslots`;
        },
    },
    "api.export_level_achievements": {
        props: ["level"],
        url: ({ company }, { level }) => {
            return `/api/v1/companies/${company.slug}/exports/levels/${level.id}/achievements`;
        },
    },
    "api.export_tax_exemption_line": {
        props: ["taxExemptionLine"],
        url: (_, { taxExemptionLine }) => {
            return `/api/v1/associations/${taxExemptionLine.association?.slug}/exports/tax-exemption-line/${taxExemptionLine.id}`;
        },
    },
    "api.export_mission_engagements": {
        props: ["mission"],
        url: ({ association, company }, { mission }) => {
            if (association) {
                return `/api/v1/associations/${association.slug}/exports/missions/${mission.id}/engagements`;
            }

            if (company) {
                return `/api/v1/companies/${company.slug}/exports/missions/${mission.id}/engagements`;
            }
        },
    },
    "api.export_mission_subscribers": {
        props: ["mission"],
        url: ({ association, company }, { mission }) => {
            if (association) {
                return `/api/v1/associations/${association.slug}/exports/missions/${mission.id}/subscribers`;
            }

            if (company) {
                return `/api/v1/companies/${company.slug}/exports/missions/${mission.id}/subscribers`;
            }
        },
    },
    "api.export_project_call": {
        props: ["projectCall"],
        url: ({ company }, { projectCall }) => {
            return `/api/v1/companies/${company.slug}/exports/project-calls/${projectCall.id}`;
        },
    },
    "api.export_project_typeform_responses": {
        url: ({ company }, { project }) => {
            return `/api/v1/companies/${company.slug}/exports/project-typeform-responses/${project.id}`;
        },
    },
    "api.export_quest_achievements": {
        props: ["quest"],
        url: ({ company }, { quest }) => {
            return `/api/v1/companies/${company.slug}/exports/quests/${quest.id}/achievements`;
        },
    },
    "api.google_first_connection": {
        url: ({ company }) => {
            return `/google/${company.slug}/first-connection`;
        },
    },
    "api.tax_exemption.download_tax_receipt": {
        url: (_, { taxExemptionLine }) => {
            return `/api/v1/tax-exemptions/${taxExemptionLine.tax_exemption_id}/${taxExemptionLine.id}/download`;
        },
    },
    "association.dashboard": {
        url: (props, params) => {
            const association = props.association || params.association;

            return `/association/${association.slug}`;
        },
    },
    "association.engagement": {
        props: ["engagement"],
        url: ({ association }, { engagement }) => {
            return `/association/${association.slug}/suivi-missions/${engagement.id}`;
        },
    },
    "association.firstOnboarding": {
        props: ["slug"],
        url: (props, { slug }) => {
            return `/association/${slug}/demarrage`;
        },
    },
    "association.members": {
        url: ({ association }) => {
            return `/association/${association.slug}/membres`;
        },
    },
    "association.messenger": {
        props: ["engagement"],
        url: (props, { engagement, ...params }) => {
            const association = props.association || params.association;

            return `/association/${association.slug}/messagerie/${engagement.id}`;
        },
    },
    "association.mission": {
        props: ["mission"],
        url: (props, { mission }) => {
            return `/association/${mission.publisher.slug}/missions/${mission.id}`;
        },
    },
    "association.mission.archive": {
        props: ["mission"],
        url: ({ association }, { mission }) => {
            return `/association/${association.slug}/missions/${mission.id}/archiver`;
        },
    },
    "association.mission.create": {
        url: ({ association }) => {
            return `/association/${association.slug}/missions/creer-une-mission`;
        },
    },
    "association.mission.duplicate": {
        props: ["mission"],
        url: ({ association }, { mission }) => {
            return `/association/${association.slug}/missions/${mission.id}/dupliquer`;
        },
    },
    "association.mission.edit": {
        props: ["mission"],
        url: (props, { mission }) => {
            return `/association/${mission.publisher.slug}/missions/${mission.id}/editer`;
        },
    },
    "association.mission.unpublish": {
        props: ["mission"],
        url: ({ association }, { mission }) => {
            return `/association/${association.slug}/missions/${mission.id}/depublier`;
        },
    },
    "association.missions": {
        url: ({ association }) => {
            return `/association/${association.slug}/missions`;
        },
    },
    "association.missions_expiring": {
        url: ({ association }) => {
            return `/association/${association.slug}/missions?expiring=true`;
        },
    },
    "association.onboarding": {
        url: ({ association }) => {
            return `/association/${association.slug}/demarrage`;
        },
    },
    "association.register.company": {
        props: ["company"],
        url: ({ company }) => {
            return `/inscription-association/${company.slug}`;
        },
    },
    "association.settings": {
        url: ({ association }) => {
            return `/association/${association.slug}/parametres`;
        },
    },
    "association.signed_url": {
        url: ({ association }) => {
            return `/associations/${association.slug}`;
        },
    },
    associationBranchFaq: {
        url: () => {
            return "https://www.notion.so/vendredi/Organiser-mon-espace-par-antenne-a072aed87e39476aa587e000d2c7bda4";
        },
    },
    "company.association": {
        url: ({ company }, { association }) => {
            return `/entreprise/${company.slug}/associations/${association.slug}`;
        },
    },
    "company.associations_search": {
        url: ({ company }) => {
            return `/entreprise/${company.slug}/associations/?bar_tab=search`;
        },
    },
    "company.dashboard": {
        url: (props, params) => {
            const company = params.company || props.company;

            return `/entreprise/${company.slug}`;
        },
    },
    "company.employees": {
        url: ({ company }) => {
            return `/entreprise/${company.slug}/volontaires`;
        },
    },
    "company.engagement": {
        props: ["engagement"],
        url: ({ company }, { engagement }) => {
            return `/entreprise/${company.slug}/suivi-missions/${engagement.id}`;
        },
    },
    "company.faq": {
        url: () => {
            return "https://aide.vendredi.cc/fr/collections/2132025-vendredi-pour-les-entreprises";
        },
    },
    "company.messenger": {
        props: ["engagement"],
        url: (props, { engagement, ...params }) => {
            const company = props.company || params.company;

            return `/entreprise/${company.slug}/missions/${engagement.mission.id}?bar_tab=volunteers&messenger_modal=${engagement.id}`;
        },
    },
    "company.mission": {
        props: ["mission"],
        url: (props, { mission }) => {
            return `/entreprise/${mission.publisher.slug}/missions/${mission.id}`;
        },
    },
    "company.mission.edit": {
        props: ["mission"],
        url: (props, { mission }) => {
            return `/entreprise/${mission.publisher.slug}/missions/${mission.id}/editer`;
        },
    },
    "company.parameters.employees": {
        url: ({ company }) => {
            return `/entreprise/${company.slug}/parametres`;
        },
    },
    "company.privacy_policy": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/${company.slug}/privacy-policy`;
        },
    },
    "company.privacy_policy_template": {
        url: () => {
            return "https://docs.google.com/document/d/1GDMe4EyaDi9G4DL3ZrGQmaLzlypCeXY7/edit?usp=sharing";
        },
    },

    "company.program": {
        props: ["program"],
        url: (props, { program }) => {
            return `/entreprise/${program.publisher.slug}/programmes/${program.id}`;
        },
    },
    "company.programs": {
        url: ({ company }) => {
            return `/entreprise/${company.slug}/programmes`;
        },
    },
    "company.quest.edit": {
        props: ["quest"],
        url: (props, { quest }) => {
            return `/entreprise/${quest.publisher.slug}/defis/${quest.id}/editer`;
        },
    },
    "company.scim_endpoint": {
        url: ({ company }) => {
            return `/scim/v2/${company.id}`;
        },
    },
    "company.signed_url": {
        url: ({ company }) => {
            return `/companies/${company.slug}`;
        },
    },
    defiscalisationFaq: {
        url: () => {
            return "https://aide.vendredi.cc/fr/articles/4002484-entreprise-la-fiscalite-et-le-mecenat-de-competences";
        },
    },
    "doc.association.createMission": {
        url: () => {
            return "https://bit.ly/3A9EZd7";
        },
    },
    "doc.badge_library": {
        url: () => {
            return "https://www.notion.so/vendredi/Sur-le-notion-df21c48fc80c4510ad7a8faecefe3371";
        },
    },
    "doc.company.createMission": {
        url: () => {
            return "https://aide.vendredi.cc/fr/articles/4729336-creer-des-missions-solidaires-internes";
        },
    },
    "doc.company.reporting": {
        url: () => {
            return "https://aide.vendredi.cc/fr/articles/6636704-le-nouveau-reporting";
        },
    },
    "doc.image.library": {
        url: () => {
            return "https://www.notion.so/vendredi/Librairie-d-images-2395815385d845f1a5344b0a2746a139";
        },
    },
    "doc.image.settings": {
        url: () => {
            return "https://aide.vendredi.cc/fr/articles/7991430-dimensions-et-cadrage-du-visuel-d-un-programme";
        },
    },
    eligibility_criteria: {
        url: (props, { language }) => {
            if (language.startsWith("fr")) {
                return "https://aide.vendredi.cc/fr/articles/3679207-les-criteres-d-eligibilite-pour-beneficier-de-vendredi";
            }

            return "https://aide.vendredi.cc/fr/articles/8336628-eligibility-criteria-to-join-vendredi-s-platform";
        },
    },
    "employee.association": {
        url: ({ company }, { association }) => {
            return `/collaborateur/${company.slug}/associations/${association.slug}`;
        },
    },
    "employee.associations": {
        url: ({ company }) => {
            return `/collaborateur/${company.slug}/associations`;
        },
    },
    "employee.challenge": {
        props: ["challenge"],
        url: (props, { challenge, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/defis/${challenge.quest.id}-${challenge.quest.slug}?panel-challenge=${challenge.id}`;
        },
    },
    "employee.challenge.agenda.google_agenda": {
        props: ["challenge"],
        url: (props, { challenge, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/challenge/${challenge.id}/agenda/google-agenda`;
        },
    },
    "employee.challenge.agenda.icalendar": {
        props: ["challenge"],
        url: (props, { challenge, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/challenge/${challenge.id}/agenda/icalendar`;
        },
    },
    "employee.challenge.agenda.microsoft_outlook": {
        props: ["challenge"],
        url: (props, { challenge, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/challenge/${challenge.id}/agenda/microsoft-outlook`;
        },
    },
    "employee.dashboard": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/mes-experiences`;
        },
    },
    "employee.download_engagement_agreement": {
        url: ({ company }, { engagementId }) => {
            return `/collaborateur/${company.slug}/engagements/${engagementId}/engagement-agreement`;
        },
    },
    "employee.engagement": {
        props: ["engagement"],
        url: ({ company }, { engagement }) => {
            return `/collaborateur/${company.slug}/mes-experiences/${engagement.id}`;
        },
    },
    "employee.pinned_missions": {
        url: ({ company }) => {
            return `/collaborateur/${company.slug}/missions?only_pinned=true`;
        },
    },
    "employee.highlight": {
        props: ["highlight"],
        url: (props, { highlight, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/temps-forts/${highlight.id}-${highlight.slug}`;
        },
    },
    "employee.home": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/accueil`;
        },
    },
    "employee.mission": {
        props: ["mission"],
        url: (props, { mission, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/missions/${mission.id}-${mission.slug}`;
        },
    },
    "employee.missions": {
        url: ({ company }) => {
            return `/collaborateur/${company.slug}/missions`;
        },
    },
    "employee.program": {
        props: ["program"],
        url: (props, { program, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/selections/${program.id}-${program.slug}`;
        },
    },
    "employee.projectCall": {
        url: ({ company }) => {
            return `/collaborateur/${company.slug}/appels-a-projets`;
        },
    },
    "employee.projectCallPreview": {
        props: ["projectCall"],
        url: ({ company }, { projectCall }) => {
            return `/collaborateur/${company.slug}/appels-a-projets/preview/${projectCall.id}`;
        },
    },
    "employee.quest": {
        props: ["quest"],
        url: (props, { quest, ...params }) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/defis/${quest.id}-${quest.slug}`;
        },
    },
    "employee.quests": {
        url: ({ company }) => {
            return `/collaborateur/${company.slug}/defis`;
        },
    },
    "employee.team": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/management-collaborateurs`;
        },
    },
    "external.suggestion": {
        url: (props, { lang }) => {
            if (lang === "fr") {
                return "https://aide.vendredi.cc/fr/articles/5767682-proposer-une-idee-sur-vendredi";
            }

            return "https://aide.vendredi.cc/fr/articles/6570575-how-to-suggest-an-initiative-on-vendredi-s-platform";
        },
    },
    "faq.companies.invite_associations": {
        url: () => {
            return "https://aide.vendredi.cc/fr/articles/3763580-inclure-mes-associations-partenaires-dans-la-demarche";
        },
    },
    home: {
        url: () => {
            return "/home";
        },
    },
    "hubspot.certification": {
        url: () => {
            return "https://meetings.hubspot.com/martin-vivement-vendredi/appel-de-certification";
        },
    },
    login: {
        url: () => {
            return "/login";
        },
    },
    logout: {
        url: (props, { redirectBack = false }) => {
            return redirectBack ? "/logout?redirect-back" : "/logout";
        },
    },
    "notion.helpUrl": {
        url: () => {
            return "https://bit.ly/3ncMOGJ";
        },
    },
    "notion.productUpdate": {
        url: () => {
            return "https://www.notion.so/vendredi/Les-nouveaut-s-de-votre-plateforme-d-engagement-a55493411d614ac79459db6eacfe46c9";
        },
    },
    privacy_policy: {
        url: () => {
            return `/privacy-policy`;
        },
    },
    "public.association": {
        props: ["association"],
        url: (props, { association }) => {
            return `/s-engager-pour/${association.slug}`;
        },
    },
    "public.association.register": {
        url: () => {
            return "/inscription-association";
        },
    },
    "public.associations": {
        url: () => {
            return "/toutes-nos-associations";
        },
    },
    "public.challenge_participate": {
        props: ["challenge"],
        url: (props, { challenge }) => {
            return `/challenge/${challenge.id}/participate`;
        },
    },
    "public.complete_account": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/finaliser-inscription`;
        },
    },
    "public.complete_email": {
        url: (props, params) => {
            return `/login/complete-email`;
        },
    },
    "public.create_account": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/inscription`;
        },
    },
    "public.employee_communication": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}`;
        },
    },
    "public.employee_complete_profile": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/inscription/complete`;
        },
    },
    "public.employee_login": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/connexion`;
        },
    },
    "public.employee_resend_verify_email": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/inscription/email/renvoyer`;
        },
    },
    "public.employee_reset_password_request": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/collaborateur/${company.slug}/mot-de-passe/oubli`;
        },
    },
    "public.engagement_timeslots_validation": {
        props: ["engagement"],
        url: (_, { engagement }) => {
            return `/validation-manager/${engagement.validation_token}`;
        },
    },
    "public.holding": {
        props: ["holding"],
        url: (props, { holding }) => {
            return `/groupe/${holding.slug}`;
        },
    },
    "public.level_achieve": {
        props: ["level"],
        url: (props, { level }) => {
            return `/defis/levels/${level.id}-${level.slug}/achieve`;
        },
    },
    "public.mission": {
        props: ["mission"],
        url: (props, { mission }) => {
            return `/missions/${mission.id}-${mission.slug}`;
        },
    },
    "public.mission_apply": {
        props: ["mission"],
        url: (props, { mission }) => {
            return `/missions/${mission.id}-${mission.slug}/apply`;
        },
    },
    "public.missions": {
        url: () => {
            return `/toutes-les-missions`;
        },
    },
    "public.project_call": {
        props: ["projectCall"],
        url: (props, { projectCall }) => {
            return `/appel-a-projets/partage/${projectCall.share_id}`;
        },
    },
    "public.project_call.login.complete_email": {
        props: ["company", "projectCall"],
        url: (props, { company, projectCall }) => {
            return `/appel-a-projets/${company.slug}/${projectCall.id}/connexion`;
        },
    },
    "public.project-calls.show": {
        props: ["projectCall"],
        url: (props, { company, projectCall }) => {
            return `/appel-a-projets/${company.slug}/${projectCall.id}`;
        },
    },
    "public.quest": {
        props: ["quest"],
        url: (props, { quest }) => {
            return `/defis/${quest.id}-${quest.slug}`;
        },
    },
    "public.reset_password": {
        url: () => {
            return "/mot-de-passe/reinitialiser";
        },
    },
    "public.reset_password_request": {
        url: () => {
            return "/mot-de-passe/oubli";
        },
    },
    "public.shared_associations_view": {
        props: ["sharedAssociationsView"],
        url: (props, { sharedAssociationsView }) => {
            return `/decouvrir-nos-associations/${sharedAssociationsView.hash}`;
        },
    },
    "public.shared_missions_view": {
        props: ["sharedMissionsView"],
        url: (props, { sharedMissionsView }) => {
            return `/decouvrir-nos-missions/${sharedMissionsView.hash}`;
        },
    },
    "saml2.export_metadata": {
        url: ({ company }) => {
            return `/saml2/${company.slug}/metadata/export`;
        },
    },
    "saml2.login": {
        url: (props, params) => {
            const company = props.company || params.company;

            return `/saml2/${company.slug}/login`;
        },
    },
    "saml2.loginTest": {
        url: ({ company }) => {
            return `/saml2/${company.slug}/login-test`;
        },
    },
    "saml2.metadata": {
        url: ({ company }) => {
            return `/saml2/${company.slug}/metadata`;
        },
    },
    terms_of_use: {
        url: () => {
            return "https://vendredi.cc/app/cgu";
        },
    },
    "user.account": {
        url: () => {
            return `/mon-compte`;
        },
    },
    "user.change_organization": {
        url: () => {
            return "/changer-organisation";
        },
    },
    "user.impersonate": {
        props: ["user"],
        url: (props, { user }) => {
            return `/impersonate/take/${user.id}`;
        },
    },
    "user.leave_impersonate": {
        url: () => {
            return "/impersonate/leave";
        },
    },
    "user.update_preferences": {
        url: () => {
            return "/mon-compte?bar_tab=preferences";
        },
    },
    welcomeKit: {
        url: (props, { lang }) => {
            if (lang === "fr") {
                return "https://www.notion.so/vendredi/Votre-Kit-de-Bienvenue-94f07cf4357540aab24e5847cd82037e";
            }

            return "https://www.notion.so/vendredi/Vendredi-Your-Welcome-Kit-ac9e3a3825894e529ae578615963a753";
        },
    },
};

/**
 * helps urls generation from SPA to outside SPA
 *
 * Needs to be initialized with basic context info (company/association) before url generation
 * => ExternalRouter.init({ association })
 *
 * When adding a route to the config, define:
 * - "url" the function that generates the url (1st argument is context, 2nd argument is extra params)
 * - "props" the optional array of keys of extra params (generating an url without those params would throw an error)
 *
 * Examples:
 * // Will use context (from initialization)
 * => ExternalRouter.url("association.missions")
 * ===> "/associations/vendredi/missions"
 *
 * // Will use context and route prop "mission" (passed as extra params)
 * => ExternalRouter.url("association.mission", { mission })
 * ===> "/associations/vendredi/mission/12"
 */
const ExternalRouter: {
    association: Association;
    company: Company;
    routes: typeof configs;

    init: (
        context: {
            association?: Association;
            company?: Company;
        },
        routes?: typeof configs
    ) => void;
    url: (name: string, props?: Params) => string;
} = {
    // cheating a bit here, forcing association and company types to exist
    // even if they may not come from init
    // so we don't have to check in routes definition if the object exist before accessing it
    association: null as any as Association,
    company: null as any as Company,
    routes: configs,

    init(context, routes = configs) {
        if (context.association) {
            this.association = context.association;
        }

        if (context.company) {
            this.company = context.company;
        }

        this.routes = routes;
    },

    url(name, props = {}) {
        // routes can come from initialization or default config
        const { routes } = this;
        const config = routes[name];

        // check we have a config for name
        if (!config) {
            throw new Error(`External route with name "${name}" does not exist`);
        }

        // check that we receive all the needed props for config
        const propsNames = Object.keys(props);
        const requiredProps = config.props || [];
        const missingPropsNames = requiredProps.filter((propName) => {
            return !propsNames.includes(propName);
        });
        const hasAllProps = missingPropsNames.length === 0;

        if (!hasAllProps) {
            throw new Error(
                `External route "${name}" is missing props ${missingPropsNames
                    .map((prop) => {
                        return `"${prop}"`;
                    })
                    .join(", ")}`
            );
        }

        try {
            return config.url(this, props) || "";
        } catch (e) {
            console.error(e);
            throw new Error(`Unable to generate external route url "${name}" because something broke`);
        }
    },
};

export default ExternalRouter;
