import _Vue, { nextTick } from "vue";

export function scrollToFirstError(this: _Vue) {
    nextTick().then(() => {
        const el = document.querySelector("div.forms-field:has(div.is-invalid)");

        if (el) {
            el?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    });
}

export default {
    install(Vue: typeof _Vue) {
        Vue.prototype.$scrollToFirstError = scrollToFirstError;
    },
};
