import Vue from "vue";

import { Company, User } from "@store/resources";
import { Id } from "@store/resources/Api";
import { Role } from "@utils/roles";

type PermissionParameters = {
    entity_id?: Id;
    publisher_id?: Id;
    role: Role;
};

class PermissionPlugin {
    private user: User | null;
    private currentCompany: Company | null;

    constructor(user: User | null = null, currentCompany: Company | null = null) {
        this.user = user;
        this.currentCompany = currentCompany;
    }

    static install(vue: typeof Vue): void {
        vue.mixin({ beforeCreate: this.init });
    }

    static init(
        this: Vue & {
            // plugin can be passed itself as option when adding plugin to Vue
            $options: {
                permission: PermissionPlugin;
            };
        }
    ): void {
        const options = this.$options;

        // injection in root
        if (options.permission) {
            this.$permission = options.permission;
        }

        // get from parent
        else if (options.parent && options.parent.$permission) {
            this.$permission = options.parent.$permission;
        }
    }

    is(allowedRoles: (Role | PermissionParameters)[]): boolean {
        if (!this.user) {
            return false;
        }

        if (this.user.is_admin) {
            if (this.currentCompany && this.currentCompany.holding_id && allowedRoles.includes(Role.HoldingAdmin)) {
                return true;
            }

            return allowedRoles.includes(Role.CompanyAdmin);
        }

        return this.getValidatedRole(allowedRoles).includes(this.user.company_role);
    }

    /**
     * Checks all additional parameters to the role
     */
    private isFulfillingConditions(parameters: PermissionParameters): boolean {
        if (!this.user) {
            return false;
        }

        if (parameters.entity_id !== this.user.entity_id) {
            return false;
        }

        if (parameters.publisher_id && parameters.publisher_id !== this.user.company.publisher_id) {
            return false;
        }

        return true;
    }

    private getValidatedRole(allowedRoles: (Role | PermissionParameters)[]): Role[] {
        return allowedRoles
            .filter((role) => {
                const isRoleConfig = typeof role === "object";

                if (isRoleConfig) {
                    if (!this.isFulfillingConditions(role)) {
                        return false;
                    }

                    return !!role.role;
                }

                return !!role;
            })
            .map((role) => {
                const isRoleConfig = typeof role === "object";

                if (isRoleConfig) {
                    return role.role;
                }

                return role;
            });
    }
}

export default PermissionPlugin;
